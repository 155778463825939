import { useRef, useLayoutEffect } from 'react';

export function useStickyHeader() {
  const header = useRef<HTMLElement>(undefined);
  useLayoutEffect(() => {
    if (header.current) {
      const sticky = header.current.offsetTop;
      window.onscroll = () => {
        if (window.pageYOffset > sticky) {
          header.current.classList.add('sticky');
        } else {
          header.current.classList.remove('sticky');
        }
      };
    }

    return () => (window.onscroll = undefined);
  }, [header.current]);
  return header;
}
