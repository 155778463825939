import * as React from 'react';

export interface DashboardContainerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The content of the dashboard, i.e., the tiles.
   */
  children?: React.ReactNode;
}

export const DashboardContainer: React.FC<DashboardContainerProps> = (props) => (
  <div {...props} className="dashboard" />
);
