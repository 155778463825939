import * as React from 'react';

export interface CarouselChildProps {
  /**
   * The content of the carousel.
   */
  children?: React.ReactNode;
}

export const CarouselChild: React.FC<CarouselChildProps> = ({ children }) => (
  <div className="carousel-item">{children}</div>
);
