import * as React from 'react';

export interface SpinnerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {}

/**
 * A loading spinner consisting of two spinning circles.
 */
export const Spinner: React.FC<SpinnerProps> = (props) => (
  <div {...props} className="app-center">
    <div className="spinner circles">Loading ...</div>
  </div>
);
