import * as React from 'react';
import { toggleAccordionEvent } from './constants';

export interface AccordionItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'title'> {
  /**
   * Sets the title of the accordion item.
   */
  title: React.ReactNode;
  /**
   * The content of the accordion item, which is toggled by the title.
   */
  children?: React.ReactNode;
}

function toggleAccordion(ev: React.MouseEvent<HTMLHeadingElement>) {
  const parent = ev.currentTarget.parentElement.parentElement;
  const detail = {
    index: Array.from(parent.parentElement.children).indexOf(parent),
  };
  ev.currentTarget.dispatchEvent(new CustomEvent(toggleAccordionEvent, { bubbles: true, detail }));
}

/**
 * Represents an accordion item.
 */
export const AccordionItem: React.FC<AccordionItemProps> = ({ title, children, ...props }) => (
  <div {...props} className="accordion-item-container">
    <h5 onClick={toggleAccordion}>
      {title} <span className="accordion-item-icon" />
    </h5>
    <div className="accordion-item-content">{children}</div>
  </div>
);
