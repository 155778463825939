import { Button } from '@smapiot/components/src/components/Button';
import * as React from 'react';
import { AzureMarketPlaceLink, AWSMarketPlaceLink, getPortalLink } from '../utils/linkUtils';
import { SectionHeader } from './SectionHeader';

interface FeatureGroup {
  name: string;
  features: Array<string>;
}

interface PriceProps {
  name: string;
  price: string;
  showCurrency: boolean;
  newTab: boolean;
  priceExplanation: string;
  description: string;
  features: Array<FeatureGroup>;
  buttonText: string;
  buttonLink: string;
}

const pricingTiers: Array<PriceProps> = [
  {
    name: 'Cloud Basic',
    price: 'Free',
    showCurrency: false,
    newTab: true,
    priceExplanation: 'just sign-up',
    description:
      'Free Software as a Service offering hosted in our infrastructure - for development workloads.',
    features: [
      {
        name: 'Features',
        features: ['Create up to 2 feeds', '10 Micro Frontends per feed', 'Create simple rules'],
      },
      {
        name: 'Operation & Support',
        features: ['Community Support'],
      },
    ],
    buttonText: 'Get Started',
    buttonLink: getPortalLink(),
  },
  {
    name: 'Azure Basic',
    price: 'Free',
    showCurrency: false,
    newTab: true,
    priceExplanation: 'your Azure subscription',
    description: 'Deploy the service as Kubernetes Extension into your subscription via the Azure Marketplace.',
    features: [
      {
        name: 'Features',
        features: ['Create 1 feed', '20 Micro Frontends per feed', 'Create simple rules'],
      },
      {
        name: 'Operation & Support',
        features: ['Community Support', 'Hosted in your Azure Kubernetes Service'],
      },
    ],
    buttonText: 'Azure Marketplace',
    buttonLink: AzureMarketPlaceLink,
  },
  {
    name: 'AWS Basic',
    price: 'Free',
    showCurrency: false,
    newTab: true,
    priceExplanation: 'your AWS account',
    description: 'Deployment of the service as Docker image into your infrastructure via the AWS Marketplace.',
    features: [
      {
        name: 'Features',
        features: ['Create 1 feed', '20 Micro Frontends per feed', 'Create simple rules'],
      },
      {
        name: 'Operation & Support',
        features: ['Community Support', 'Hosted in your AWS infrastructure'],
      },
    ],
    buttonText: 'AWS Marketplace',
    buttonLink: AWSMarketPlaceLink,
  },
  {
    name: 'Docker Pro',
    price: '3.250',
    showCurrency: true,
    newTab: false,
    priceExplanation: 'per year',
    description: 'For self-hosting in your environment provisioned as Docker image - for production workloads​.',
    features: [
      {
        name: 'Features',
        features: [
          'Create unlimited feeds',
          'Publish unlimited Micro Frontends',
          'Advanced rule management',
          'Configuration of Micro Frontends',
          'Feature flag management',
          'Analyze styles of Micro Frontends',
          'Micro Frontend dependencies',
          'Hosting of your application shell',
          '... and many more ...',
        ],
      },
      {
        name: 'Operation & Support',
        features: ['Standard support included', 'Optional premium support', 'Optional training session'],
      },
    ],
    buttonText: 'Request Offer',
    buttonLink: '#contact',
  },
];

const PriceCard: React.FC<PriceProps> = (props) => (
  <div className="col m-0 p-0">
  <div className="card" style={{ minHeight: '760px', minWidth: '265px' }}>
    <div className="card-body pb-2 p-3">
      <div className="package">{props.name}</div>
      <div className="price mt-4">
        {props.showCurrency ? <span className="currency">€</span> : <span></span>}
        <span className="value">{props.price}</span>
      </div>
      <div className="period"><strong>{props.priceExplanation}</strong></div>
      <p className="mt-3 text-justify p-small">{props.description}</p>
      {props.features.map((group) => (
        <div key={group.name}>
          <h5 className="text-left">{group.name}</h5>
          <ul className="list-unstyled li-space-sm">
            {group.features.map((f) => (
              <li className="text-left" key={f}>
                <div className="d-flex">
                  <strong className="pr-2 green">&#x2713;</strong>
                  <div className="p-tiny-light">{f}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="button-wrapper">
        <a target={props.newTab ? '_blank' : '_self'} href={props.buttonLink}>
          <Button style={{ width: '200px' }}>{props.buttonText}</Button>
        </a>
      </div>
    </div>
  </div>
  </div>
);

export const PricingList: React.FC = () => (
  <div id="pricing" className="ex-cards-2 bg-light">
    <SectionHeader heading="Plans and Pricing" subheader="Choose the right plan that fits your needs." />
    <div className="container">
      <div className="row">
        {pricingTiers.map((pt) => (
          <PriceCard
            key={pt.name}
            name={pt.name}
            price={pt.price}
            priceExplanation={pt.priceExplanation}
            description={pt.description}
            features={pt.features}
            showCurrency={pt.showCurrency}
            buttonText={pt.buttonText}
            buttonLink={pt.buttonLink}
            newTab={pt.newTab}
          />
        ))}
      </div>
    </div>
  </div>
);
