import * as React from 'react';
import { AutoCompleteItem } from './internal/AutoCompleteItem';
import { Suggestion } from '../../types';

export interface SuggestionListProps {
  /**
   * The suggestions to render.
   */
  suggestions: Array<string | Suggestion>;
  /**
   * The index of the currently highlighted suggestion.
   */
  cursor: number;
  /**
   * Indicates if suggestions are currently being loaded.
   */
  loading?: boolean;
  /**
   * Indicates if the list should be visibile.
   */
  visible?: boolean;
  /**
   * Callback to be used when an entry is selected.
   */
  onSelect(value: string): void;
}

export const SuggestionList: React.FC<SuggestionListProps> = ({
  onSelect,
  suggestions,
  loading,
  visible,
  cursor,
}) => {
  const ref = React.useRef(undefined);

  React.useEffect(() => {
    const container = ref.current;

    if (container && cursor >= 0 && cursor < suggestions.length) {
      const item = container.children.item(cursor);

      if (item) {
        const area = item.parentElement.parentElement;
        const top = item.offsetTop - area.scrollTop;
        const bottom = top + item.clientHeight;

        if (top < 0) {
          item.scrollIntoView(true);
        } else if (bottom > area.offsetHeight) {
          item.scrollIntoView(false);
        }
      }
    }
  }, [cursor, suggestions]);

  if (loading) {
    return (
      <div className="tag-search-result is-loading">
        <div className="spinner circles" />
      </div>
    );
  } else if (visible && suggestions.length > 0) {
    return (
      <div className="tag-search-result">
        <ul className="list-group" ref={ref}>
          {suggestions.map((suggestion, idx) => (
            <AutoCompleteItem
              onSelect={onSelect}
              key={idx}
              isHighlighted={cursor === idx}
              name={typeof suggestion === 'string' ? suggestion : suggestion.display}
              value={typeof suggestion === 'string' ? suggestion : suggestion.value}
            />
          ))}
        </ul>
      </div>
    );
  }

  // tslint:disable-next-line:no-null-keyword
  return null;
};
