import * as React from 'react';
import {
  DateFieldState,
  DateFieldUpdate,
  getMonthDetails,
  getPureDate,
  getToday,
  toDateString,
} from './internal/common';
import { CalendarPopup } from './internal/CalendarPopup';
import { useOutsideClick } from '../../hooks';

const absMinDate = new Date(-93692595208000);
const absMaxDate = new Date(253402210800000);

export interface DateFieldProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'value' | 'defaultValue' | 'onChange'> {
  /**
   * Sets the change handler to receive a new value.
   * @param value The updated choice state.
   */
  onChange?(value: Date): void;
  /**
   * The initial value for managed mode.
   */
  value?: Date | string | number;
  /**
   * The initial value for managed mode.
   */
  defaultValue?: Date | string | number;
  /**
   * The optional label of the date field.
   */
  label?: React.ReactChild;
  /**
   * Indicates an error state of the date field.
   */
  error?: boolean;
  /**
   * Indicates the minimum date range of the date field.
   */
  minDate?: Date | string | number;
  /**
   * Indicates the maximum date range of the date field.
   */
  maxDate?: Date | string | number;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
  /**
   * Indicates whether the field cannot be modified.
   */
  readOnly?: boolean;
}

export const DateField: React.FC<DateFieldProps> = ({
  onChange,
  error,
  label,
  value,
  readOnly,
  defaultValue = new Date(),
  children,
  ...props
}) => {
  const minDate = getPureDate(props.minDate || absMinDate);
  const maxDate = getPureDate(props.maxDate || absMaxDate);
  const today = getToday();
  const el = React.useRef(undefined);
  const controlled = React.useMemo(() => value !== undefined, []);
  const initial = getPureDate(controlled ? value : defaultValue);

  const [state, dispatch] = React.useReducer(
    (state: DateFieldState, values: DateFieldUpdate) => {
      const { force, ...update } = values;
      const newState = {
        ...state,
        ...update,
      };

      if (!force && newState.selectedDay !== state.selectedDay) {
        const value = newState.selectedDay;
        setTimeout(() => onChange?.(value));
      }

      if (controlled && !force) {
        newState.selectedDay = state.selectedDay;
        newState.input = state.input;
      }

      return newState;
    },
    {
      valid: true,
      input: toDateString(initial),
      month: initial.getUTCMonth(),
      year: initial.getUTCFullYear(),
      monthDetails: [],
      selectedDay: initial,
      showCalendar: false,
    },
    (state) => ({
      ...state,
      monthDetails: getMonthDetails(initial.getUTCFullYear(), initial.getUTCMonth()),
    }),
  );
  const openCalendar = React.useCallback(() => dispatch({ showCalendar: true }), []);

  React.useEffect(() => {
    const selectedDay = getPureDate(value || defaultValue);

    if (state.selectedDay !== selectedDay) {
      dispatch({
        valid: true,
        selectedDay,
        input: toDateString(selectedDay),
        force: true,
      });
    }
  }, [controlled ? value : undefined]);

  const handleClickOutsideCalendar = React.useCallback(() => {
    dispatch({ showCalendar: false });
  }, []);

  useOutsideClick(el, handleClickOutsideCalendar);

  const change = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fullDate = getPureDate(e.target.value);
      const invalid = isNaN(fullDate.valueOf()) || fullDate > maxDate || fullDate < minDate;

      dispatch({
        valid: !invalid,
        selectedDay: invalid ? undefined : fullDate,
        input: e.target.value,
      });
    },
    [props.minDate, props.maxDate],
  );

  return (
    <div ref={el} className="form-group">
      <input
        className={`form-control${!error && state.valid ? '' : ' error'}`}
        type="text"
        readOnly={readOnly}
        placeholder="YYYY-MM-DD"
        value={state.input}
        onChange={change}
        onFocus={openCalendar}
      />
      {label && <label className="text-floating">{label}</label>}
      <i className="fas fa-calendar date-picker-icon" onClick={openCalendar} />
      {children}
      {!readOnly && state.showCalendar && (
        <CalendarPopup state={state} min={minDate} max={maxDate} today={today} dispatch={dispatch} />
      )}
    </div>
  );
};
