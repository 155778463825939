import * as React from 'react';

export interface NotFoundProps {
  /**
   * The page's children.
   */
  children?: React.ReactNode;
}

export const NotFound: React.FC<NotFoundProps> = ({ children }) => (
  <div className="shout text-center">
    <h2>404</h2>
    <h3>Oh no! Page not found.</h3>
    {children}
  </div>
);
