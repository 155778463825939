import * as React from 'react';

export interface TopBarProps {
  /**
   * The top bar's content.
   */
  children?: React.ReactNode;
}

export const TopBar: React.FC<TopBarProps> = ({ children }) => (
  <div className="container topbar">{children}</div>
);
