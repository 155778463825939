import { useEffect } from 'react';

export function useModal(open: boolean) {
  useEffect(() => {
    const body = document.body;

    if (open) {
      body.style.top = `-${window.scrollY}px`;
      body.classList.add('modal-open');
    } else {
      const offset = -parseInt(body.style.top || '0', 10);
      body.classList.remove('modal-open');
      body.style.top = '';
      window.scrollTo(0, offset);
    }

    return () => {};
  }, [open]);
}
