import * as React from 'react';

export interface HeaderMenuProps {
  /**
   * The content to show in the header menu.
   */
  children?: React.ReactNode;
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({ children }) => (
  <ul className="row header-rows" id="navbar-menu-content">
    {children}
  </ul>
);
