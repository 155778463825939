export const AzureMarketPlaceLink = 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/smapiotgmbh1620751517969.piral-cloud-services?tab=Overview';
export const AWSMarketPlaceLink = "https://aws.amazon.com/marketplace/pp/prodview-fy4fgcac6kryk";
export const SessionBookingLink = 'https://arch-session.booking.smapiot.com';

export function getPortalLink(): string {
    const defaultLink = 'https://portal.piral.cloud';
    const envLink = process.env.PIRAL_CLOUD_PORTAL;
  
    return envLink === undefined ? defaultLink : envLink;
  }
  
export function getDocsLink(): string {
    const defaultLink = 'https://docs.piral.cloud';
    const envLink = process.env.PIRAL_CLOUD_DOCS;
  
    return envLink === undefined ? defaultLink : envLink;
  }

