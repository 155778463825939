import * as React from 'react';

function selectText(element: HTMLElement) {
  const ctr = 'createTextRange';
  const body: any = document.body;

  if (element && document.createRange && window.getSelection) {
    const range = document.createRange();
    const sel = window.getSelection();
    sel && sel.removeAllRanges();

    try {
      range.selectNodeContents(element);
    } catch (e) {
      range.selectNode(element);
    }

    sel && sel.addRange(range);
  } else if (typeof body[ctr] === 'function') {
    const range = body[ctr]();
    range.moveToElementText(element);
    range.select();
  } else {
    return false;
  }

  return true;
}

const CopiedInfo: React.ComponentType = () => (
  <div className="copy-info">
    <i className="fas fa-check" /> Copied to clipboard.
  </div>
);

export interface ClipboardTextProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The content / text ready for copying.
   */
  children?: React.ReactNode;
}

export const ClipboardText: React.FC<ClipboardTextProps> = ({ children, ...props }) => {
  const [copied, setCopied] = React.useState(false);
  const el = React.useRef(undefined);

  React.useEffect(() => {
    if (copied) {
      const id = setTimeout(() => setCopied(false), 3000);
      return () => clearInterval(id);
    }

    return () => {};
  }, [copied]);

  const copyContent = () => {
    const element = el.current;

    if (selectText(element)) {
      document.execCommand('Copy');
      setCopied(true);
    }
  };

  return (
    <div {...props} className="message-frame clipboard-text">
      <output ref={el} onClick={copyContent}>
        {children}
      </output>
      {copied && <CopiedInfo />}
    </div>
  );
};
