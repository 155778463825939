import * as React from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbItemProps {
  /**
   * Signals that the given breadcrumb is active.
   */
  current?: boolean;
  /**
   * Represents the path of the page relating to the breadcrumb.
   */
  path?: string;
  /**
   * The content of the breadcrumb.
   */
  children?: React.ReactNode;
}

/**
 * A single breadcrumb to be used in a breadcrumb container.
 */
export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ current, path, children }) => {
  if (current) {
    return <li>{children}</li>;
  } else {
    return (
      <li>
        <Link to={path}>{children}</Link>
      </li>
    );
  }
};
