import * as React from 'react';

export interface TeaserCarouselItemProps {
  /**
   * The image URL of the teaser slide.
   */
  image: string;
  /**
   * The content of the slide.
   */
  children?: React.ReactNode;
}

export const TeaserCarouselItem: React.FC<TeaserCarouselItemProps> = ({ children, image }) => (
  <div className="wide-teaser text-center no-select" style={{ backgroundImage: `url(${image})` }}>
    {children}
  </div>
);
