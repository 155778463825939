import * as React from 'react';

const units = {
  byte: {
    c: ['B', 'kB', 'MB', 'GB', 'TB', 'PB'],
    d: 1024,
  },
  weight: {
    c: ['g', 'kg', 't', 'kt', 'Mt', 'Gt'],
    d: 1000,
  },
  abbr: {
    c: ['', 'k', 'M', 'G', 'T', 'P'],
    d: 1000,
  },
  none: {
    c: [],
    d: 1,
  },
};

function getValue(value: number) {
  if (value < 10) {
    return ~~(value * 10) / 10;
  } else {
    return ~~value;
  }
}

function getQuantity(value: number, unit: keyof typeof units): React.ReactNode {
  const u = units[unit];

  if (!u || u.d === 1) {
    return getValue(value);
  }

  return getSize(value, u.c, u.d);
}

function getCategory(value: number, divider: number, maxpow: number): [number, number] {
  let i = 0;

  while (value > divider && i < maxpow) {
    value = value / divider;
    i++;
  }

  return [i, value];
}

function getSize(value: number, categories: Array<string>, divider: number) {
  const [cat, val] = getCategory(value, divider, categories.length - 1);
  const lab = categories[cat];
  return lab ? `${getValue(val)}${lab}` : getValue(value);
}

export interface StatsProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The content of the statistic row, i.e., the statistic items.
   */
  children?: React.ReactNode;
}

export const StatsRow: React.FC<StatsProps> = (props) => <div {...props} className="stats-row" />;

export interface StatsValueItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The value to represent. Some (ideally non-negative) quantity.
   */
  value: number;
  /**
   * The unit to choose.
   * @default 'none'
   */
  unit?: keyof typeof units;
  /**
   * The label to display.
   */
  children: React.ReactNode;
}

export const StatsValueItem: React.FC<StatsValueItemProps> = ({ value, unit = 'none', children }) => {
  return (
    <div className="stats-value-item">
      <div className="stats-quantity">{getQuantity(value, unit)}</div>
      <div className="stats-label">{children}</div>
    </div>
  );
};

export interface StatsCircleChartProps {
  /**
   * The value to represent. Ideally 0-100.
   */
  value: number;
  /**
   * The color to use.
   * @default '#FFC007'
   */
  color?: string;
  /**
   * The label to display.
   */
  children?: React.ReactNode;
}

export const StatsCircleChart: React.FC<StatsCircleChartProps> = ({ value, color = '#FFC007', children }) => (
  <div className="stats-circle-chart">
    <svg viewBox="0 0 36 36" className="stats-circle-chart-circle" stroke={color}>
      <path
        className="stats-circle-chart-background"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
      <path
        className="stats-circle-chart-progress"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        strokeDasharray={`${value}, 100`}></path>
      <text x="18" y="20.35" className="stats-circle-chart-percentage">
        {getValue(value)}%
      </text>
    </svg>
    <p className="stats-circle-chart-title">{children}</p>
  </div>
);

export interface StatsProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The content of the statistics.
   */
  children?: React.ReactNode;
}

export const Stats: React.FC<StatsProps> = (props) => <div {...props} />;
