import * as React from 'react';

export interface TableHeader {
  /**
   * The ID of the header.
   */
  id: string;
  /**
   * The width of the column.
   */
  width?: string;
  /**
   * The content of the table cell.
   */
  cell: React.ReactChild;
}

export interface TableRow {
  /**
   * The ID of the row.
   */
  id: string;
  /**
   * The content cells of the row.
   */
  cells: Array<React.ReactChild>;
  /**
   * Defines the colspan of the cells.
   */
  spans?: Array<number>;
  /**
   * Determines if the row is hidden.
   */
  hidden?: boolean;
}

export interface TableProps {
  /**
   * The headers of the table.
   */
  headers: Array<TableHeader>;
  /**
   * The rows of the table.
   */
  rows: Array<TableRow>;
}

function getSpan(row: TableRow, index: number, cols: number) {
  const l = row.cells.length;

  if (l !== cols) {
    const { spans = [] } = row;

    if (spans.length > index) {
      const span = row.spans[index];
      return `span ${span}`;
    } else if (l === 1) {
      return `span ${cols}`;
    } else if (l === index + 1) {
      const obtained = spans.reduce((sum, v) => sum + v, 0);
      const span = spans.length + 1 + cols - l - obtained;
      return `span ${span}`;
    }
  }

  return undefined;
}

function getBoundingClientRectOfContents(this: HTMLDivElement) {
  const isContents = window.getComputedStyle(this).display === 'contents';

  if (isContents) {
    // Usually, this will only yield 0s; so we need to make sure that it returns
    // the dimensions of its contained content.
    const el = this;
    const rects = Array.from(el.children).map((m) => m.getBoundingClientRect());
    const first = rects[0];
    const last = rects[rects.length - 1];
    const coords = {
      bottom: first.bottom,
      height: first.height,
      left: first.left,
      right: last.right,
      top: first.top,
      width: rects.reduce((sum, m) => sum + m.width, 0),
      x: first.x,
      y: first.y,
    };

    return {
      ...coords,
      toJSON() {
        return JSON.stringify(coords);
      },
    };
  }

  return HTMLElement.prototype.getBoundingClientRect.call(this);
}

function modifyRow(el: HTMLDivElement) {
  if (el) {
    // changes computation to allow dragging
    el.getBoundingClientRect = getBoundingClientRectOfContents;
  }
}

function getTitle(node: React.ReactNode) {
  if (typeof node === 'string') {
    return node;
  } else if (React.isValidElement(node)) {
    const children = node.props.children || [];

    if (Array.isArray(children)) {
      const child = children.find(m => typeof m === 'string');

      if (child) {
        return child;
      }
    }
  }

  return '';
}

export const Table = React.forwardRef<HTMLDivElement, TableProps>(({ headers, rows }, ref) => {
  const gridTemplateColumns = headers.map((m) => m.width || '1fr').join(' ');

  return (
    <div className="table-container">
      <div className="table" style={{ gridTemplateColumns }}>
        <div className="row header">
          {headers.map((header, headerIndex) => (
            <div className="cell" key={header.id} style={{ width: header.width }}>
              {header.cell}
            </div>
          ))}
        </div>
        <div className="table-rows" ref={ref}>
          {rows.map((row) => (
            <div
              className={row.hidden ? 'row row-hidden' : 'row row-visible'}
              style={{ gridTemplateColumns }}
              data-id={row.id}
              key={row.id}
              ref={modifyRow}>
              {row.cells.map((cell, index) => {
                const header = headers[index];
                const gridColumn = getSpan(row, index, headers.length);
                const title = gridColumn ? '' : getTitle(header.cell);

                return (
                  <div className="cell" data-title={title} key={header.id} style={{ gridColumn }}>
                    {cell}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
