import * as React from 'react';
import { dig, getPureTime, getTimeParts, TimeFieldState, TimeFieldUpdate, toTimeString } from './common';

export interface PickerPopupProps {
  value: number;
  min: number;
  max: number;
  dispatch: React.Dispatch<TimeFieldUpdate>;
}

interface PickerButtonProps {
  onClick(): void;
  type: 'top-arrow' | 'bottom-arrow';
}

const PickerButton: React.FC<PickerButtonProps> = ({ onClick, type }) => (
  <div className="mdpch-button">
    <div className="mdpchb-inner" onClick={onClick}>
      <span className={`mdpchbi-${type}`} />
    </div>
  </div>
);

export const PickerPopup: React.FC<PickerPopupProps> = ({ value, min, max, dispatch }) => {
  const isBetweenMinMax = (time: number) => time <= max && time >= min;

  const change = (time: number) => {
    if (isBetweenMinMax(time)) {
      dispatch({
        valid: true,
        time,
        showPicker: true,
        input: toTimeString(time),
      });
    }
  };

  const [hours, minutes, seconds, milliseconds] = getTimeParts(value);
  const setHours = (delta: number) => {
    change(getPureTime([hours + delta, minutes, seconds, milliseconds]));
  };
  const setMinutes = (delta: number) => {
    change(getPureTime([hours, minutes + delta, seconds, milliseconds]));
  };
  const setSeconds = (delta: number) => {
    change(getPureTime([hours, minutes, seconds + delta, milliseconds]));
  };
  const setMilliseconds = (delta: number) => {
    change(getPureTime([hours, minutes, seconds, milliseconds + delta]));
  };

  return (
    <div className="mdp-container mdp-time">
      <div className="mdp-time-column">
        <PickerButton type="top-arrow" onClick={() => setHours(1)} />
        <strong>{dig(hours, 2)}</strong>
        <PickerButton type="bottom-arrow" onClick={() => setHours(-1)} />
      </div>
      <div className="mdp-time-column mdp-time-column-divider">:</div>
      <div className="mdp-time-column">
        <PickerButton type="top-arrow" onClick={() => setMinutes(1)} />
        <strong>{dig(minutes, 2)}</strong>
        <PickerButton type="bottom-arrow" onClick={() => setMinutes(-1)} />
      </div>
      <div className="mdp-time-column mdp-time-column-divider">:</div>
      <div className="mdp-time-column">
        <PickerButton type="top-arrow" onClick={() => setSeconds(1)} />
        <strong>{dig(seconds, 2)}</strong>
        <PickerButton type="bottom-arrow" onClick={() => setSeconds(-1)} />
      </div>
      <div className="mdp-time-column mdp-time-column-divider">.</div>
      <div className="mdp-time-column">
        <PickerButton type="top-arrow" onClick={() => setMilliseconds(1)} />
        <strong>{dig(milliseconds, 3)}</strong>
        <PickerButton type="bottom-arrow" onClick={() => setMilliseconds(-1)} />
      </div>
    </div>
  );
};
