import * as React from 'react';

export interface BreadcrumbsContainerProps extends Omit<React.HTMLAttributes<HTMLUListElement>, 'className'> {
  /**
   * Indicates if the breadcrumb container should be hidden.
   */
  hidden?: boolean;
  /**
   * The content of the breadcrumb.
   */
  children?: React.ReactNode;
}

/**
 * A breadcrumb container holding a set of breadcrumbs.
 */
export const BreadcrumbsContainer: React.FC<BreadcrumbsContainerProps> = ({
  hidden = false,
  children,
  ...props
}) =>
  !hidden && (
    <ul {...props} className="breadcrumbs">
      {children}
    </ul>
  );
