export interface TimeFieldState {
  valid: boolean;
  input: string;
  time: number;
  showPicker: boolean;
}

export interface TimeFieldUpdate extends Partial<TimeFieldState> {
  force?: boolean;
}

export type TimeParts = [number, number, number, number];

const rx = /^(\d{1,2}):(\d{1,2})(:(\d{1,2})(.(\d{1,3}))?)?$/;

export const oneSecond = 1000;
export const oneMinute = 60 * oneSecond;
export const oneHour = 60 * oneMinute;
export const oneDay = 24 * oneHour;

export function dig(value: number, length: number) {
  return value.toString(10).padStart(length, '0');
}

export function getTimeParts(timestamp: number | string | TimeParts | Date): TimeParts {
  const time = getPureTime(timestamp);
  const hours = ~~(time / oneHour);
  const fullHours = hours * oneHour;
  const minutes = ~~((time - fullHours) / oneMinute);
  const fullMinutes = fullHours + minutes * oneMinute;
  const seconds = ~~((time - fullMinutes) / oneSecond);
  const fullSeconds = fullMinutes + seconds * oneSecond;
  const milliseconds = time - fullSeconds;
  return [hours, minutes, seconds, milliseconds];
}

export function toTimeString(timestamp: number | string | TimeParts | Date) {
  const [hours, minutes, seconds, milliseconds] = getTimeParts(timestamp);
  return `${dig(hours, 2)}:${dig(minutes, 2)}:${dig(seconds, 2)}.${dig(milliseconds, 3)}`;
}

export function getPureTime(timestamp: number | string | TimeParts | Date) {
  if (typeof timestamp === 'string') {
    rx.lastIndex = 0;
    const g = rx.exec(timestamp);

    if (g) {
      const hours = g[1];
      const minutes = g[2];
      const seconds = g[4] || '0';
      const milliseconds = g[6] || '0';
      return getPureTime(+milliseconds + +seconds * oneSecond + +minutes * oneMinute + +hours * oneHour);
    }

    return 0;
  } else if (Array.isArray(timestamp)) {
    const [hours, minutes, seconds, milliseconds] = timestamp;
    return getPureTime(+milliseconds + seconds * oneSecond + minutes * oneMinute + hours * oneHour);
  } else if (timestamp instanceof Date) {
    return timestamp.getTime() % oneDay;
  }

  return timestamp > 0 ? (timestamp > oneDay ? timestamp % oneDay : timestamp) : 0;
}
