import * as React from 'react';
import { DateField } from '../DateField';
import { TimeField } from '../TimeField';
import { oneDay } from '../TimeField/internal/common';

export interface DateTimeFieldProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'value' | 'defaultValue' | 'onChange'> {
  /**
   * Sets the change handler to receive a new value.
   * @param value The updated choice state.
   */
  onChange?(value: Date): void;
  /**
   * The initial value for managed mode.
   */
  value?: string | number | Date;
  /**
   * The initial value for managed mode.
   */
  defaultValue?: string | number | Date;
  /**
   * The optional label of the date time field.
   */
  label?: React.ReactChild;
  /**
   * Indicates an error state of the date time field.
   */
  error?: boolean;
  /**
   * Indicates the minimum date time range of the field.
   */
  minDateTime?: string | number | Date;
  /**
   * Indicates the maximum date time range of the field.
   */
  maxDateTime?: string | number | Date;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
  /**
   * Indicates whether the field cannot be modified.
   */
  readOnly?: boolean;
}

function getDate(value: number | string | Date) {
  if (typeof value === 'number' || typeof value === 'string') {
    return new Date(value);
  } else if (value instanceof Date) {
    return value;
  } else {
    return new Date();
  }
}

function getTime(value: Date) {
  if (value instanceof Date) {
    const v = value.getTime();
    return v % oneDay;
  }

  return 0;
}

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  value,
  defaultValue,
  minDateTime,
  maxDateTime,
  label,
  onChange,
  children,
  ...props
}) => {
  const controlled = React.useMemo(() => value !== undefined, []);
  const [val, setVal] = React.useState(getDate(controlled ? value : defaultValue));

  React.useEffect(() => {
    if (controlled) {
      setVal(getDate(value));
    }
  }, [value]);

  const change = (date: Date) => {
    if (date !== val) {
      if (!controlled) {
        setVal(date);
      }

      onChange?.(date);
    }
  };
  const changeDate = (date: Date) => {
    if (date !== undefined) {
      const t = getTime(val);
      const o = getTime(date);
      date.setTime(date.getTime() - o + t);
      change(date);
    }
  };
  const changeTime = (time: number) => {
    if (time !== undefined) {
      const date = new Date(val);
      const o = getTime(val);
      date.setTime(date.getTime() - o + time);
      change(date);
    }
  };

  return (
    <div className="date-time-group">
      <DateField
        value={val}
        onChange={changeDate}
        minDate={minDateTime}
        maxDate={maxDateTime}
        label={label || undefined}
        {...props}>
        {children}
      </DateField>
      <TimeField
        value={val}
        onChange={changeTime}
        minTime={minDateTime}
        maxTime={maxDateTime}
        label={label ? ' ' : undefined}
        {...props}
      />
    </div>
  );
};
