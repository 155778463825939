import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';

export interface TrackerEvent {
  url: string;
  location: Location<any>;
}

export interface TrackerProps extends RouteComponentProps {
  visiting(e: TrackerEvent): void;
  visited(e: TrackerEvent): void;
}

export const Tracker: React.FC<TrackerProps> = ({ history, location, visiting, visited }) => {
  React.useEffect(() => {
    const url = history.createHref(location);
    const ev = { url, location };
    visiting(ev);
    return () => visited(ev);
  }, [location.pathname]);

  // tslint:disable-next-line:no-null-keyword
  return null;
};
