import * as React from 'react';

export interface ActionsCardProps {
  /**
   * Determines if the card should be expanded initially.
   */
  opened?: boolean;
  /**
   * The title shown in the card.
   */
  title: React.ReactChild;
  /**
   * The card's actions.
   */
  actions: React.ReactChild;
  /**
   * The cards class name.
   */
  className?: string;
  /**
   * The body of the card.
   */
  children?: React.ReactNode;
  /**
   * Called if clicked on the header.
   */
  onHeaderClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const ActionsCard = React.forwardRef<HTMLDivElement, ActionsCardProps>(
  ({ opened, className, title, actions, children, onHeaderClick }, ref) => {
    const [open, setOpen] = React.useState(opened);

    React.useEffect(() => {
      // open is not a dependency intentionally
      // we only care if opened change and look
      // at "open" at this point in time vs the
      // value of "opened"
      if (open !== opened) {
        setOpen(opened);
      }
    }, [opened]);

    return (
      <div className={`feed-card ${className || ''}`} ref={ref}>
        <div className="feed-card-header" onClick={onHeaderClick}>
          <div className="feed-card-header-title">{title}</div>
          <div className="feed-card-header-actions">
            {actions}
            <a
              href="#"
              title={open ? 'Collapse' : 'Expand'}
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
              aria-expanded={open}>
              <i className={`fas fa-chevron-${open ? 'up' : 'down'}`} />
            </a>
          </div>
        </div>
        <div className={`collapse ${open ? 'show' : 'hide'}`}>
          <div className="feed-card-body">{children}</div>
        </div>
      </div>
    );
  },
);
