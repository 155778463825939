import * as React from 'react';

export interface ActionProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Sets the icon (className) to use.
   */
  icon: string;
  /**
   * The component to use. Usually that is just "a".
   */
  of?: string | React.ComponentType;
  /**
   * The text of the action link / button.
   */
  children?: React.ReactNode;
  /**
   * Determines if the action should be disabled.
   */
  disabled?: boolean;

  [key: string]: unknown;
}

/**
 * Represents an Action wrapped in an anchor element or the given component.
 */
export const Action: React.FC<ActionProps> = ({ icon, of = 'a', children, ...props }) => {
  return React.createElement(
    of,
    props as any,
    <span className="action-item">
      <i className={icon} />
      {children}
    </span>,
  );
};
