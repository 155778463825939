import * as React from 'react';

export interface ModalsDialogProps {
  /**
   * The content of the dialog.
   */
  children?: React.ReactNode;
}

function clickedInside(e: React.SyntheticEvent) {
  return e.stopPropagation();
}

export const ModalsDialog: React.FC<ModalsDialogProps> = ({ children }) => (
  <div className="modal-dialog" onClick={clickedInside}>
    {children}
  </div>
);
