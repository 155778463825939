import { Button } from '@smapiot/components';
import * as React from 'react';
import { SectionHeader } from './SectionHeader';
import { SessionBookingLink } from '../utils/linkUtils';

export const BookSession: React.FC = () => (
  <div id="booksession" className="bg-light">
    <div className="container">
      <SectionHeader
        heading="Book Free Architecture Session"
        subheader="Schedule an appointment for a free Architecture Session and a first discussion about your project."
      />
      <div className="row flex-column align-items-center">
        <div className="p-2 pb-5">
          <a target="_blank" href={SessionBookingLink}>
            <Button style={{ minWidth: '240px' }}>Book Free Architecture Session</Button>
          </a>
        </div>
      </div>
    </div>
  </div>
);
