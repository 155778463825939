import * as React from 'react';
import { SuggestionList } from '../../components';
import { useForwardedRef, useSuggestionList, SuggestionResults } from '../../hooks';
import { StrictUnion } from '../../types';

export interface InputFieldBaseProps {
  /**
   * Callback invoked when the value changes.
   * @param value The changed value.
   */
  onChange?(value: string): void;
  /**
   * The label of the input field, if any.
   */
  label?: React.ReactChild;
  /**
   * The error state to show, if any.
   */
  error?: boolean;
  /**
   * The body of the card.
   */
  children?: React.ReactNode;
  /**
   * The onSuggest state to show, if any data was provided.
   */
  onSuggest?(input: string): SuggestionResults;
}

export interface InputFieldMultilineProps
  extends InputFieldBaseProps,
    Partial<Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>> {
  /**
   * Indicates that a textarea should be used underneath.
   */
  multiline: true;
}

export interface InputFieldSinglelineProps
  extends InputFieldBaseProps,
    Partial<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>> {
  /**
   * Indicates that a simple input field should be used underneath.
   */
  multiline?: false;
}

export type InputFieldProps = StrictUnion<InputFieldMultilineProps | InputFieldSinglelineProps>;

export const InputField = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputFieldProps>(
  ({ label, type = 'text', multiline = false, onChange, error, value, children, onSuggest, ...props }, ref) => {
    const cls = `form-control${value ? ' active' : ''}${error ? ' error' : ''}`;
    const [el, suggestionsInfos, setSuggestionsInfos, loadSuggestions] = useSuggestionList();
    const input = useForwardedRef(ref);
    const change = React.useCallback(
      (e) => {
        const value = e.target.value;

        if (typeof onSuggest === 'function') {
          const results = onSuggest?.(value);
          loadSuggestions(results);
        }

        onChange?.(value);
      },
      [onChange, onSuggest],
    );

    const select = React.useCallback(
      (value: string) => {
        input.current.value = value;
        onChange?.(value);
        setSuggestionsInfos((data) => ({ ...data, cursor: -1, isVisible: false }));
      },
      [onChange],
    );

    const keyboardNavigation = (ev: React.KeyboardEvent) => {
      const { key } = ev;

      if (!suggestionsInfos.isVisible) {
        // nothing to do here
      } else if (key === 'Enter') {
        if (suggestionsInfos.cursor !== -1) {
          const suggestion = suggestionsInfos.suggestions[suggestionsInfos.cursor];
          const suggestedValue = typeof suggestion === 'string' ? suggestion : suggestion.value;

          if (typeof value === 'undefined') {
            input.current.value = suggestedValue;
          }

          onChange?.(suggestedValue);
          setSuggestionsInfos((data) => ({ ...data, cursor: -1, isVisible: false }));
          ev.preventDefault();
        }
      } else if (key === 'ArrowDown' || key === 'ArrowUp') {
        const len = suggestionsInfos.suggestions.length;

        if (len > 0) {
          const delta = suggestionsInfos.isVisible ? (key === 'ArrowDown' ? +1 : -1) : 0;
          const cursor = (suggestionsInfos.cursor + delta + len) % len;
          setSuggestionsInfos((data) => ({ ...data, cursor, isVisible: true }));
        }

        ev.preventDefault();
      } else if (key === 'Escape') {
        setSuggestionsInfos((data) => ({ ...data, isVisible: false }));
        ev.preventDefault();
      }
    };

    return (
      <div className="form-group" ref={el}>
        {multiline ? (
          <textarea
            {...(props as any)}
            rows={props.rows || 5}
            className={cls}
            onKeyDown={keyboardNavigation}
            value={value}
            ref={input}
            onChange={change}
          />
        ) : (
          <input
            {...(props as any)}
            type={type}
            className={cls}
            onKeyDown={keyboardNavigation}
            value={value}
            ref={input}
            onChange={change}
          />
        )}
        {label && <label className="text-floating">{label}</label>}
        {children}
        <SuggestionList
          onSelect={select}
          loading={suggestionsInfos.loading}
          visible={suggestionsInfos.isVisible}
          suggestions={suggestionsInfos.suggestions}
          cursor={suggestionsInfos.cursor}
        />
      </div>
    );
  },
);
