import * as React from 'react';

export interface ImageCardProps {
  /**
   * The URL for getting the image.
   */
  image: string;
  /**
   * The title of the card.
   */
  title: React.ReactChild;
  /**
   * The description text of the card.
   */
  description: React.ReactChild;
  /**
   * Further optional details of the card.
   */
  details?: React.ReactChild;
}

export const ImageCard: React.FC<ImageCardProps> = ({ image, title, description, details }) => (
  <div className={`card image-card text-center${details ? ' details' : ''}`}>
    <div className="image">
      <img src={image} alt={typeof title === 'string' ? title : 'Logo'} />
    </div>
    <h5>{title}</h5>
    <div>
      <small>{description}</small>
    </div>
    {details && <div className="card-details">{details}</div>}
  </div>
);
