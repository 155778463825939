import * as React from 'react';

export interface AutoCompleteItemProps {
  name: string;
  value?: string;
  isHighlighted: boolean;
  onSelect(value: string): void;
}

export const AutoCompleteItem: React.FC<AutoCompleteItemProps> = ({ name, isHighlighted, onSelect, value = name }) => {
  return (
    <li onClick={() => onSelect(value)} className={isHighlighted ? 'active' : ''}>
      <div className="list-group-item-name">
        <p>{name}</p>
      </div>
    </li>
  );
};
