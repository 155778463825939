import * as React from 'react';

export interface TimelineItemProps {
  /**
   * The class name of a font awesome icon to display.
   */
  icon: string;
  /**
   * The content of the breadcrumb.
   */
  children?: React.ReactNode;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({ icon, children }) => (
  <li>
    <div className="timeline-badge text-center">
      <i className={icon} />
    </div>
    <div className="timeline-panel">{children}</div>
  </li>
);
