import { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef, useState } from 'react';
import { useOutsideClick } from './useOutsideClick';
import { Suggestion } from '../types';

export interface SuggestionData {
  isVisible: boolean;
  cursor: number;
  loading: boolean;
  suggestions: Array<string | Suggestion>;
}

export type SuggestionResults = Array<string | Suggestion> | Promise<Array<string | Suggestion>>;

export interface LoadSuggestions {
  (results: SuggestionResults): void;
}

export function useSuggestionList(): [
  MutableRefObject<any>,
  SuggestionData,
  Dispatch<SetStateAction<SuggestionData>>,
  LoadSuggestions,
] {
  const el = useRef(undefined);
  const suggestPromise = useRef(undefined);
  const [suggestionsInfos, setSuggestionsInfos] = useState<SuggestionData>({
    isVisible: false,
    cursor: -1,
    loading: false,
    suggestions: [],
  });

  const handleClickOutsideSuggestions = useCallback(() => {
    setSuggestionsInfos((data) => ({ ...data, isVisible: false }));
  }, []);

  const loadSuggestions = useCallback((results: SuggestionResults) => {
    setSuggestionsInfos((data) => ({
      ...data,
      cursor: -1,
      loading: !!results,
      isVisible: false,
      suggestions: [],
    }));

    if (results) {
      if (suggestPromise.current) {
        suggestPromise.current.active = false;
      }

      const sp = {
        active: true,
      };

      Promise.resolve(results)
        .catch(() => [])
        .then((suggestions) => {
          if (sp.active) {
            setSuggestionsInfos((data) => ({ ...data, loading: false, suggestions, isVisible: true }));
          }
        });

      suggestPromise.current = sp;
    }
  }, []);

  useOutsideClick(el, handleClickOutsideSuggestions);

  return [el, suggestionsInfos, setSuggestionsInfos, loadSuggestions];
}
