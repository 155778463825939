import * as React from 'react';

export interface StandardErrorProps {
  /**
   * The page's children.
   */
  children?: React.ReactNode;
}

export const StandardError: React.FC<StandardErrorProps> = ({ children }) => (
  <div className="shout text-center">
    <h2>500</h2>
    <h3>Something went wrong.</h3>
    {children}
  </div>
);
