import * as React from 'react';
import { TableRow, TableHeader, Action, Button } from '../components';

const nextSort = {
  none: 'asc',
  asc: 'desc',
  desc: 'none',
} as const;

const sortIcons = {
  none: 'fas fa-sort',
  asc: 'fas fa-sort-up',
  desc: 'fas fa-sort-down',
};

export function useSortTable(
  initialRows: Array<TableRow>,
  initialHeaders: Array<TableHeader>,
  sorterFunctions: Record<string, (a, b) => number>,
  sortableHeaderIds: Array<string>,
): [rows: Array<TableRow>, headers: Array<TableHeader>] {
  const [sortingState, setSortingState] = React.useState<[string, number, 'asc' | 'desc' | 'none']>(['', -1, 'none']);

  const headers = React.useMemo(() => {
    const [oldHeader, _, oldSort] = sortingState;

    const handleOnSort = (header: TableHeader, index: number) => {
      if (oldHeader === header.id) {
        const newSort = nextSort[oldSort];
        setSortingState([header.id, index, newSort]);
      } else {
        const newSort = nextSort.none;
        setSortingState([header.id, index, newSort]);
      }
    };

    return initialHeaders.map((header, index) =>
      sortableHeaderIds?.includes(header.id)
        ? {
            ...header,
            cell: (
              <>
                {header.cell}
                <Action
                  of={Button}
                  icon={sortIcons[oldHeader === header.id ? oldSort : 'none']}
                  title="Sort"
                  kind="ghost"
                  onClick={() => handleOnSort(header, index)}
                />
              </>
            ),
          }
        : header,
    );
  }, [sortingState, initialHeaders, sortableHeaderIds]);

  const rows = React.useMemo(() => {
    const detailsRows: Record<string, TableRow> = {};
    const finalRows: Array<TableRow> = [];
    const sortableRows: Array<TableRow> = [];
    const [headerId, headerIndex, newSort] = sortingState;

    if (newSort !== 'none') {
      // Splitting table rows into sortable and detail rows because we only want to sort the parent rows
      for (let i = 0; i < initialRows.length; i++) {
        const currentRow = initialRows[i];

        if (currentRow.hidden === undefined) {
          sortableRows.push(currentRow);
        } else {
          detailsRows[initialRows[i - 1].id] = currentRow;
        }
      }

      if (newSort === 'asc' || newSort === 'desc') {
        sortableRows.sort((a, b) => {
          const cellA = a.cells[headerIndex];
          const cellB = b.cells[headerIndex];
          const sorter = sorterFunctions[headerId];
          const sortResult = sorter?.(cellA, cellB) ?? 0;
          return sortResult * (newSort === 'asc' ? 1 : -1);
        });
      }

      // Insert details rows into below their previous parent rows
      for (const parentRow of sortableRows) {
        finalRows.push(parentRow);

        if (detailsRows[parentRow.id]) {
          finalRows.push(detailsRows[parentRow.id]);
        }
      }

      return finalRows;
    }

    return initialRows;
  }, [sortingState, initialRows]);

  return [rows, headers];
}
