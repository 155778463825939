import * as React from 'react';

export interface NotificationsItemProps {
  /**
   * The id of the notification. Not actively used.
   */
  id: string;
  /**
   * The kind of toast notification.
   */
  kind?: 'danger' | 'success' | 'info' | 'warning';
  /**
   * The title of the toast notification.
   */
  title: React.ReactChild;
  /**
   * Callback invoked when it should be closed.
   */
  close(): void;
  /**
   * The content of the toast.
   */
  children?: React.ReactNode;
}

export const NotificationsItem: React.FC<NotificationsItemProps> = ({
  close,
  kind = 'info',
  title,
  children,
}) => (
  <div className={`notification ${kind}`}>
    <div className="notification-content">
      {title && <div className="notification-title">{title}</div>}
      <div className="notification-description">{children}</div>
    </div>
    <div className="notification-close" onClick={close} />
  </div>
);
