import * as React from 'react';

export interface TimelineContainerProps {
  /**
   * The content of the timeline.
   */
  children?: React.ReactNode;
}

export const TimelineContainer: React.FC<TimelineContainerProps> = ({ children }) => (
  <ul className="timeline">{children}</ul>
);
