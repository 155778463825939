import * as React from 'react';

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'className'> {
  /**
   * Sets the change handler to receive a new value.
   * @param value The updated checked state.
   */
  onChange?(value: boolean): void;
  /**
   * The optional label of the checkbox.
   */
  label?: React.ReactChild;
  /**
   * Indicates an error state of the checkbox usage.
   */
  error?: boolean;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

/**
 * A checkbox form element.
 */
export const Checkbox: React.FC<CheckboxProps> = ({ onChange, error, label, children, ...props }) => {
  const cls = `form-control${error ? ' error' : ''}`;
  const change = React.useCallback((e) => onChange?.(e.target.checked), [onChange]);

  return (
    <div className="form-group">
      <label className="checked-container" {...{ disabled: props.disabled }}>
        <input {...props} type="checkbox" className={cls} onChange={change} />
        <span className="checkmark" />
        {label && <span className="label">{label}</span>}
      </label>
      {children}
    </div>
  );
};
