import * as React from 'react';

function getTileClass(cols: number, rows: number) {
  cols = Math.min(cols, 12);
  rows = Math.min(rows, 12);
  return `tile rows-${rows} cols-${cols}`;
}

export interface DashboardTileProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The columns used by the tile.
   */
  columns: number;
  /**
   * The rows used by the tile.
   */
  rows: number;
  /**
   * The content of the tile.
   */
  children?: React.ReactNode;
}

export const DashboardTile: React.FC<DashboardTileProps> = ({ children, columns, rows, ...props }) => (
  <div {...props} className={getTileClass(columns, rows)}>
    {children}
  </div>
);
