import { useLayoutEffect } from 'react';

export function useLockBodyScroll(active: boolean) {
  useLayoutEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = 'visible');
    }

    return () => {};
  }, [active]);
}
