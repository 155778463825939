import * as React from 'react';

interface HeaderProps {
  heading: string;
  subheader?: string;
}

export const SectionHeader: React.FC<HeaderProps> = (props) => (
  <div className="container">
    <div className="row d-flex flex-column align-items-center">
      <div className="mt-4 p-3">
        <h1 className="text-center green p-2">{props.heading}</h1>
        { props.subheader &&
          <p className="p-large text-center">
            {props.subheader}
          </p>
      }
      </div>
    </div>
  </div>
);
