import * as React from 'react';

export interface ToggleSwitchProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'readOnly' | 'className'> {
  /**
   * Callback invoked when the value changes.
   * @param value The changed value.
   */
  onChange?(value: boolean): void;
  /**
   * The label of the input field, if any.
   */
  label?: React.ReactChild;
  /**
   * Indicates if the toggling is currently in progress.
   */
  progress?: boolean;
  /**
   * The error state to show, if any.
   */
  error?: boolean;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ onChange, error, label, progress, children, ...props }) => {
  const inputClass = `form-control${error ? ' error' : ''}`;
  const sliderClass = `slider${progress ? ' progress' : ''}`;
  const change = React.useCallback((e) => onChange?.(e.target.checked), [onChange]);

  return (
    <div className="form-group">
      <label className="checked-container" {...{ disabled: props.disabled }}>
        <input {...props} type="checkbox" readOnly={progress} className={inputClass} onChange={change} />
        <span className={sliderClass} />
        {label && <span className="label">{label}</span>}
      </label>
      {children}
    </div>
  );
};
