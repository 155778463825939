import * as React from 'react';

export interface NotificationsContainerProps {
  /**
   * The content of the notification container, i.e., the individual toasts.
   */
  children?: React.ReactNode;
}

export const NotificationsContainer: React.FC<NotificationsContainerProps> = ({ children }) => (
  <div className="notifications">{children}</div>
);
