const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export interface DateFieldState {
  valid: boolean;
  input: string;
  month: number;
  monthDetails: Array<MonthDetails>;
  selectedDay: Date;
  showCalendar: boolean;
  year: number;
}

export interface DateFieldUpdate extends Partial<DateFieldState> {
  force?: boolean;
}

export interface MonthDetails {
  date: number;
  day: number;
  dayString: string;
  month: number;
  timestamp: Date;
}

export function getMonthDetails(year: number, month: number) {
  const firstDay = new Date(Date.UTC(year, month, 1));
  const monthArray: Array<MonthDetails> = [];
  const rows = 6;
  const cols = 7;
  firstDay.setUTCDate(firstDay.getUTCDate() - firstDay.getUTCDay());

  for (let row = 0, index = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++, index++) {
      const day = index % 7;
      const timestamp = new Date(firstDay);
      timestamp.setUTCDate(timestamp.getUTCDate() + index);
      monthArray.push({
        timestamp: timestamp,
        date: timestamp.getUTCDate(),
        day,
        month: timestamp.getUTCMonth() - month,
        dayString: daysMap[day],
      });
    }
  }

  return monthArray;
}

export function toDateString(timestamp: Date | number | string) {
  const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

  if (isNaN(date.valueOf())) {
    return undefined;
  }

  return date.toISOString().substring(0, 10);
}

export function getPureDate(timestamp: Date | number | string) {
  const str = toDateString(timestamp);
  return new Date(str);
}

export function getToday() {
  return getPureDate(new Date());
}
