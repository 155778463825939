import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export interface BoundaryErrorProps {
  error?: any;
}

export interface BoundaryProps extends RouteComponentProps {
  ShowError: React.FC<BoundaryErrorProps>;
  children?: React.ReactNode;
}

export const Boundary = withRouter(
  class extends React.Component<BoundaryProps> {
    state = {
      error: undefined,
    };

    componentDidCatch(error: Error) {
      const { history } = this.props;
      const unlisten = history.listen(() => {
        this.setState({
          error: undefined,
        });
        unlisten();
      });
      this.setState({
        error,
      });
    }

    render() {
      const { error } = this.state;
      const { children, ShowError } = this.props;

      if (error) {
        return <ShowError error={error} />;
      }

      return children;
    }
  },
);
