import * as React from "react";
import { Link } from "react-router-dom";

interface NavItemProps {
  text: string;
  path: string;
  target?: string;
  active?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ text, active, path, target }) => (
  <li className="nav-item">
    <a className="nav-link p-large text-center" target={target} href={path}>
      {text} {active && <span className="sr-only">(current)</span>}
    </a>
  </li>
);

export interface NavElement {
  text: string;
  path: string;
  target?: string;
}

interface NavigationProps{
  header: string;
  items: Array<NavElement>;
}

export const Navigation: React.FC<NavigationProps> = ({ header, items}) => {

  const [open, setOpen] = React.useState(false);

  return (
  <nav className="navbar navbar-expand-md fixed-top navbar-light">
    <div className="container p-0">
      <a href="/">
      <img
        width="80px"
        height="80px"
        src={require('../images/piral-cloud-logo-inv.svg')}
        alt="Piral Cloud" />
              </a>
      <Link className="navbar-brand logo-text page-scroll ml-4" to="/">
        {header}
      </Link>

      <button
        onClick={() => setOpen(!open)}
        className="navbar-toggler mx-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded={open}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse${open ? " show" : ""}`}
        id="navbarNav"
        onClick={() => setOpen(!open)}
      >
        <ul className="navbar-nav ml-auto p-2">
          {items.map(i => <NavItem key={i.path} text={i.text} path={i.path} target={i.target}/> )}
        </ul>
      </div>
    </div>
  </nav>
)
}

