import { useCallback, useState } from 'react';

export function useToggleIndices(
  multiple = false,
  initialIndices: Array<number> = [],
): [open: Array<number>, toggle: (index: number) => void] {
  const [openIndices, setOpenIndices] = useState(initialIndices);
  const toggle = useCallback(
    (index: number) =>
      setOpenIndices((indices) =>
        indices.includes(index) ? indices.filter((m) => m !== index) : multiple ? [...indices, index] : [index],
      ),
    [multiple],
  );

  return [openIndices, toggle];
}
