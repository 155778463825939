import * as React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { useStickyHeader } from '../../hooks/useStickyHeader';

export interface PageHeaderProps {
  /**
   * The logo element.
   */
  logo?: React.ReactChild;
  /**
   * The URL to the homepage.
   */
  home?: string;
  /**
   * The content of the page header.
   */
  children?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ logo = <Logo />, home = '/', children }) => {
  const [open, setOpen] = React.useState(false);
  const header = useStickyHeader();
  useLockBodyScroll(open);

  return (
    <header ref={header} data-open={open}>
      <nav className="container row" onClick={() => open && setOpen(false)}>
        <div className="row header-brand">
          <Link to={home} className="navbar-brand">
            {logo}
          </Link>

          <div className="navbar-toggler">
            <button
              onClick={() => setOpen(!open)}
              type="button"
              data-toggle="collapse"
              aria-controls="navbar-menu-content"
              aria-expanded={open}
              aria-label="Toggle navigation">
              <i className="fas fa-bars" />
            </button>
          </div>
        </div>

        {children}
      </nav>
    </header>
  );
};
