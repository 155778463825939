import * as React from 'react';
import { TabTitle } from './TabTitle';
import { useTabs } from '../../hooks/useTabs';

export interface TabsContainerProps {
  /**
   * The tabs to render in the tab container.
   */
  children: Array<React.ReactElement>;

  /**
   * Defines if the whole space should be used.
   */
  fitted?: boolean;

  /**
   * Defines what variant is used.
   */
  variant?: 'enclosed' | 'line';
}

export const TabsContainer: React.FC<TabsContainerProps> = ({ children, fitted, variant = 'line' }) => {
  const [selectedTab, handlers] = useTabs(children);

  return (
    <div className="tabs">
      <ul className={`tab-bar tab-bar-${variant} ${fitted ? 'tab-bar-fitted' : ''}`}>
        {children.map((item, index) => (
          <TabTitle key={index} title={item.props.title} setActive={handlers[index]} active={index === selectedTab} />
        ))}
      </ul>
      <div className="tab-content">
        {children.map((child, index) => (
          <div key={index} style={{ display: index === selectedTab ? 'block' : 'none' }}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};
