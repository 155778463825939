import * as React from 'react';
import { DateFieldState, DateFieldUpdate, getMonthDetails, toDateString } from './common';

const daysMapShort = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const monthMap = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

interface PickerButtonProps {
  onClick(): void;
  type: 'left-arrows' | 'left-arrow' | 'right-arrow' | 'right-arrows';
}

const PickerButton: React.FC<PickerButtonProps> = ({ onClick, type }) => (
  <div className="mdpch-button">
    <div className="mdpchb-inner" onClick={onClick}>
      <span className={`mdpchbi-${type}`} />
    </div>
  </div>
);

export interface CalendarPopupProps {
  state: DateFieldState;
  min: Date;
  max: Date;
  today: Date;
  dispatch: React.Dispatch<DateFieldUpdate>;
}

export const CalendarPopup: React.FC<CalendarPopupProps> = ({ state, today, min, max, dispatch }) => {
  const isDateBetweenMinMaxDate = (date: Date) => date <= max && date >= min;
  const isCurrentDay = (date: Date) => date.valueOf() === today.valueOf();
  const isSelectedDay = (date: Date) => date.valueOf() === state.selectedDay?.valueOf();
  const getMonthStr = (month: number) => monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

  const onDateClick = React.useCallback((date: Date) => {
    dispatch({
      valid: true,
      selectedDay: date,
      showCalendar: false,
      input: toDateString(date),
    });
  }, []);

  const setYear = (offset: number) => {
    const year = state.year + offset;

    dispatch({
      monthDetails: getMonthDetails(year, state.month),
      year: year,
    });
  };

  const setMonth = (offset: number) => {
    let year = state.year;
    let month = state.month + offset;

    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }

    dispatch({
      year,
      month,
      monthDetails: getMonthDetails(year, month),
    });
  };

  return (
    <div className="mdp-container">
      <div className="mdpc-head">
        <PickerButton onClick={() => setYear(-1)} type="left-arrows" />
        <PickerButton onClick={() => setMonth(-1)} type="left-arrow" />
        <div className="mdpch-container">
          <div className="mdpchc-year">{state.year}</div>
          <div className="mdpchc-month">{getMonthStr(state.month)}</div>
        </div>
        <PickerButton onClick={() => setMonth(1)} type="right-arrow" />
        <PickerButton onClick={() => setYear(1)} type="right-arrows" />
      </div>
      <div className="mdpc-body">
        <div className="c-container">
          <div className="cc-head">
            {daysMapShort.map((d, i) => (
              <div key={i} className="cch-name">
                {d}
              </div>
            ))}
          </div>
          <div className="cc-body">
            {state.monthDetails.map((day, index) =>
              isDateBetweenMinMaxDate(day.timestamp) ? (
                <div
                  className={
                    'c-day-container ' +
                    (day.month !== 0 ? ' disabled' : '') +
                    (isCurrentDay(day.timestamp) ? ' highlight' : '') +
                    (isSelectedDay(day.timestamp) ? ' highlight-green' : '')
                  }
                  key={index}>
                  <div className="cdc-day">
                    <span onClick={() => onDateClick(day.timestamp)}>{day.date}</span>
                  </div>
                </div>
              ) : (
                <div
                  className={`c-day-container ${isCurrentDay(day.timestamp) ? 'current-day-disabled ' : 'disabled'}`}
                  key={index}>
                  <div className="cdc-day">
                    <span>{day.date}</span>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
