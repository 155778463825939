import * as React from 'react';

export interface SearchBoxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange' | 'value' | 'defaultValue' | 'placeholder'
  > {
  /**
   * The current search input.
   */
  input: string;
  /**
   * The search handler.
   * @param input The given input.
   */
  onSearch(input: string): void;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

export const SearchBox: React.FC<SearchBoxProps> = ({ input, onSearch, children, ...props }) => {
  const search = React.useCallback((e) => onSearch?.(e.target.value), [onSearch]);

  return (
    <div className="search-container">
      <input {...props} type="search" placeholder="Search ..." value={input} onChange={search} />
      <i className="fas fa-search" />
      {children}
    </div>
  );
};
