import * as React from 'react';
import { Link } from 'react-router-dom';
import { InvertedLogo } from './InvertedLogo';

export interface PageFooterProps {
  logo?: React.ReactChild;
  left: React.ReactChild;
  right: React.ReactChild;
  copyrightHtml: string | React.ReactChild;
  home?: string;
}

export const PageFooter: React.FC<PageFooterProps> = ({
  logo = <InvertedLogo />,
  home = '/',
  left,
  right,
  copyrightHtml,
}) => (
  <footer>
    <div className="container">
      <div className="row footer-columns">
        <div>
          <Link to={home} className="logo">
            {logo}
          </Link>
        </div>
        <div>{left}</div>
        <div>{right}</div>
      </div>

      {typeof copyrightHtml === 'string' ? (
        <p className="copy-rights h3" dangerouslySetInnerHTML={{ __html: copyrightHtml }} />
      ) : (
        <p className="copy-rights h3">{copyrightHtml}</p>
      )}
    </div>
  </footer>
);
