import * as React from 'react';

export interface UploadFieldProps {
  /**
   * The label of the input field, if any.
   */
  emptyLabel?: React.ReactChild;
  /**
   * The label of the input field, if any.
   */
  fullLabel?: React.ReactChild;
  /**
   * The error state to show, if any.
   */
  error?: boolean;
  /**
   * Callback invoked when the value changes.
   * @param value The changed value.
   */
  onChange?(files: Array<File>): void;
  /**
   * Indicates if the field should be disabled.
   */
  disabled?: boolean | undefined;
  /**
   * Indicates if multiple files can be uploaded, too.
   */
  multiple?: boolean | undefined;
  /**
   * The body of the field.
   */
  children?: React.ReactNode;
}

function onDragOver(ev: React.DragEvent) {
  ev.preventDefault();
}

export const UploadField: React.FC<UploadFieldProps> = ({
  emptyLabel,
  fullLabel,
  disabled,
  multiple,
  onChange,
  error,
  children,
}) => {
  const cls = `upload-field${error ? ' error' : ''}`;
  const [over, setOver] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const inputRef = React.useRef<HTMLInputElement>();
  const openFilePicker = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  const changeFiles = React.useCallback(
    (fileList: FileList) => {
      const files: Array<File> = [];

      if (!disabled) {
        if (multiple) {
          files.push(...(fileList as any));
        } else {
          files.push(fileList[0]);
        }

        setFiles(files);

        if (typeof onChange === 'function') {
          onChange(files);
        }
      }
    },
    [onChange, multiple, disabled],
  );

  const onDragEnter = React.useCallback(() => setOver(() => true), []);

  const onDragLeave = React.useCallback(() => setOver(() => false), []);

  const onDrop = React.useCallback(
    (ev: React.DragEvent) => {
      ev.preventDefault();
      onDragLeave();
      changeFiles(ev.dataTransfer.files);
    },
    [changeFiles],
  );

  const change = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      changeFiles(ev.target.files);
    },
    [changeFiles],
  );

  return (
    <div className="form-group">
      <div
        className={cls}
        data-over={over}
        data-files={files.length}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
        onClick={openFilePicker}>
        <label>{files.length === 0 ? emptyLabel : fullLabel}</label>
        <input type="file" ref={inputRef} onChange={change} multiple={multiple} />
        {children}
      </div>
    </div>
  );
};
