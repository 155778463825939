import { Button } from '@smapiot/components/src/components/Button';
import * as React from 'react';
import { SessionBookingLink } from '../utils/linkUtils';

export const Intro: React.FC = () => (
  <header className="header mt-4" id="header">
    <div className="container">
      <div>
        <div className="row align-middle">
          <div className="col-lg-8">
            <div className="d-lg-none">
              <img
                className="img-fluid"
                width="240px"
                height="240px"
                src={require('../images/intro.png')}
                alt="Piral Cloud"
              />
            </div>
            <div className="p-3">
              <h1 className="text-left">
                Piral <span className="green">Cloud</span>
              </h1>
              <h3 className="text-left">Your frontend accelerator for a seamless portal experience</h3>
              <p className="text-justify p-large my-3">
                Use the <span className="green">Piral Cloud</span> to transform your portal solution into a new
                revolutionizing digital experience, unlocking innovation and unparalleled convenience for your
                customers.
              </p>
              <p className="text-justify p-large my-3">
                Including Micro Frontend Discovery and Feature Flag Management, our Piral Cloud Services provide a rich
                feature set for the dynamic composition of modular web applications and support you in building leading
                edge portal solutions.
              </p>
              <p className="text-justify p-large my-3">
                Schedule a <span className="green">free architecture session</span> with our experts to find out how you
                can benefit from the rich feature set of the Piral Cloud.
              </p>
              <div className="row justify-content-center p-2">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-3">
                  <a target="_blank" href={SessionBookingLink}>
                    <Button style={{ minWidth: '240px' }}>Book Free Architecture Session</Button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-none d-flex align-items-center" >
            <img
              className="img-fluid m-4"
              width="350px"
              height="350px"
              src={require('../images/intro.png')}
              alt="Piral Cloud"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
);
