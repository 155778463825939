import * as React from 'react';

export interface TagProps {
  /**
   * The primary content of the tag.
   */
  label?: React.ReactNode;
  /**
   * The secondary content of the tag.
   */
  children?: React.ReactNode;
}

export const Tag: React.FC<TagProps> = ({ label, children }) => {
  return (
    <span className="tag-label">
      {label}
      {children}
    </span>
  );
};
