import * as React from 'react';
import { SectionHeader } from './SectionHeader';

interface CustomersItemProps {
  image: any;
  link: string;
}

const customers: Array<CustomersItemProps> = [
  {
    image: require('../images/customer-logos/casablanca.png'),
    link: 'https://www.casablanca.at',
  },
  {
    image: require('../images/customer-logos/consolinno.png'),
    link: 'https://consolinno.de',
  },
  {
    image: require('../images/customer-logos/prowin.png'),
    link: 'https://prowin.net',
  },
  {
    image: require('../images/customer-logos/stadtwien_wrwks.png'),
    link: '',
  },
  {
    image: require('../images/customer-logos/wuh.png'),
    link: 'https://www.wh.group',
  },
  {
    image: require('../images/customer-logos/zeiss.png'),
    link: 'https://www.zeiss.de/meditec-ag/home.html',
  },
];

export const Customers: React.FC = () => (
  <div className="basic-2">
    <div className="container">
      <div className="row">
        <SectionHeader heading="Customers" subheader="These are some of our valued Piral Cloud customers:" />
        <div className="col-12">
          <div className="image-container">
            {customers.map((c) => (
                <a href={c.link}>
                <img
                key={c.image}
                className="img-fluid"
                style={{ maxHeight: '48px',maxWidth: '200px', marginLeft:'50px',marginRight:'50px', marginTop:'20px',marginBottom:'20px'}}
                src={c.image}
                alt={c.link}
              />
              </a>
            ))}
            </div>
        </div>
      </div>
    </div>
  </div>
);