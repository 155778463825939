import * as React from 'react';
import { SessionBookingLink } from '../utils/linkUtils';

export interface FooterProps {
  gitHubLink: string;
  twitterLink: string;
  linkedInLink: string;
  aboutHeader: string;
  aboutDescription: string;
  contactMail: string;
}

export const Footer: React.FC<FooterProps> = ({
  gitHubLink,
  twitterLink,
  linkedInLink,
  aboutHeader,
  aboutDescription,
  contactMail,
}) => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-col first">
                <h6>{aboutHeader}</h6>
                <p className="p-tiny">{aboutDescription}</p>
              </div>
              <div className="footer-col second">
                <h6>Links</h6>
                <ul className="list-unstyled li-space-lg p-tiny">
                  <li>
                    <a target="_blank" href="https://smapiot.com/about">
                      About smapiot
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://smapiot.com/legal/privacy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://smapiot.com/legal/imprint">
                      Imprint
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col third">
                <h6>Menu</h6>
                <ul className="list-unstyled li-space-lg p-tiny">
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#pricing">Plans & Pricing</a>
                  </li>
                  <li>
                    <a target="_blank" href={SessionBookingLink}>
                      Book Free Session
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col fourth">
                <div className="social-media-icons">
                  <span className="fa-stack">
                    <a href={gitHubLink} target="_blank">
                      <i className="fas fa-circle fa-stack-2x"></i>
                      <i className="fab fa-github fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href={twitterLink} target="_blank">
                      <i className="fas fa-circle fa-stack-2x"></i>
                      <i className="fab fa-twitter fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href={linkedInLink} target="_blank">
                      <i className="fas fa-circle fa-stack-2x"></i>
                      <i className="fab fa-linkedin-in fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
                <p className="p-tiny">
                  We would love to hear from you: <br />
                  <a href={`mailTo:${contactMail}`}>
                    <strong>{contactMail}</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="p-tiny">© {year} smapiot GmbH</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
