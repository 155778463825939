import * as React from 'react';

export interface FooterMenuProps {
  /**
   * The title of the footer menu list.
   */
  title: React.ReactChild;
  /**
   * The footer menu content / list.
   */
  children?: React.ReactNode;
}

export const FooterMenu: React.FC<FooterMenuProps> = ({ title, children }) => (
  <div className="footer-menu">
    <h3>{title}</h3>
    <ul>{children}</ul>
  </div>
);
