import * as React from 'react';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  /**
   * The kind of the button.
   */
  kind?: 'primary' | 'success' | 'secondary' | 'danger' | 'info' | 'warning' | 'ghost';
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
}

/**
 * A button element using the defined kind of button.
 */
export const Button: React.FC<ButtonProps> = ({ kind = 'success', children, ...props }) => (
  <button {...props} className={`btn btn-${kind}`}>
    {children}
  </button>
);
