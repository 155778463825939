import * as React from 'react';

export interface AccountInfoProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  /**
   * The name of the user.
   */
  userName: string;
}

/**
 * Represents a user icon next to the given account name.
 */
export const AccountInfo: React.FC<AccountInfoProps> = ({ userName, ...props }) => (
  <div {...props} className="account-info">
    <i className="fas fa-user" /> {userName}
  </div>
);
