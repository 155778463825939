import * as React from 'react';

export interface ToastProps {
  /**
   * The title of the toast.
   */
  title: string;
  /**
   * The font awesome class name to use.
   */
  icon: string;
  /**
   * The kind of toast to display.
   */
  kind?: 'danger' | 'success' | 'info' | 'warning';
  /**
   * The content of the toast.
   */
  children?: React.ReactNode;
}

export const Toast: React.FC<ToastProps> = ({ title, kind = 'success', icon, children }) => (
  <div className={`toast message-frame ${kind}`}>
    <div className="icon">
      <i className={icon} />
    </div>
    <div className="content">
      <div className="type">{title}</div>
      <div className="message">{children}</div>
    </div>
  </div>
);
