import * as React from 'react';

export interface StepIndicatorProps {
  /**
   * The mode of the step indicator. By default its "vertical".
   * @default vertical
   */
  mode?: 'vertical' | 'horizontal';
  /**
   * Sets the active step index.
   */
  active?: number;
  /**
   * The labels to show.
   */
  labels: Array<React.ReactChild>;
  /**
   * The content of the step to display.
   */
  children?: React.ReactNode;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  active,
  mode = 'vertical',
  labels = [],
  children,
}) => {
  const stepperClass = mode === 'horizontal' ? 'stepper-horizontal' : 'stepper-vertical';
  const nodes = labels.map((child, index) => (
    <div className="stepper-step" data-active={active >= index}>
      <span className="stepper-preview">
        <span className="stepper-indicator">
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <circle cx="12" cy="12" r="12" />
            <text x="12" y="16" text-anchor="middle">
              {index + 1}
            </text>
          </svg>
        </span>
        <span className="stepper-label">
          <span>{child}</span>
        </span>
      </span>
      {active === index && mode === 'vertical' && (
        <div className="stepper-content">
          <div>{children}</div>
        </div>
      )}
    </div>
  ));

  for (let i = nodes.length; --i; ) {
    const connector = (
      <div className="stepper-connector">
        <hr />
      </div>
    );
    nodes.splice(i, 0, connector);
  }

  return <div className={stepperClass}>{nodes}</div>;
};
