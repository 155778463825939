import * as React from 'react';
import { Spinner } from '../../components/Spinner';

export interface LoaderProps<T> {
  loading: boolean;
  data: T;
  children(result: T, error?: Error): React.ReactElement;
}

export function Loader<T>({ loading, data, children }: LoaderProps<T>): React.ReactElement {
  if (loading) {
    return <Spinner />;
  } else if (data instanceof Error) {
    return children(undefined, data);
  } else {
    return children(data, undefined);
  }
}
