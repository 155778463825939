import { Button, InputField } from '@smapiot/components';
import * as React from 'react';
import { SectionHeader } from './SectionHeader';

interface ContactFormProps {
  formspreeReceivingMail: string;
}

const isEmail =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const ContactForm: React.FC<ContactFormProps> = ({ formspreeReceivingMail }) => {
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const disable = React.useMemo(() => {
    return !isEmail.test(email) || !message;
  }, [message, email]);

  return (
    <form method="post" onSubmit={(e) => disable && e.preventDefault()} action="https://formspree.io/f/mknkabzp">
      <input type="text" name="_gotcha" style={{ display: 'none' }} />
      <input type="text" name="subject" defaultValue="Piral Feed Service" style={{ display: 'none' }} />
      <InputField name="_replyto" value={email} onChange={setEmail} label="Email" />
      <InputField name="message" rows={5} value={message} onChange={setMessage} label="Message" multiline />
      <Button disabled={disable} style={{ width: '180px' }}>
        Submit
      </Button>
      <div className="d-flex align-items-center my-4">
        <div className="d-inline-block m-3">
          <i className="fas fa-info-circle" />
        </div>
        <div className="d-inline-block p-tiny m-3">
          We use an external service (Formspree) to handle our contact form. By clicking "submit" you agree to be
          redirected to Formspree for sending the entered data.
        </div>
      </div>
    </form>
  );
};

export const Contact: React.FC = () => (
  <div id="contact" className="mb-5" >
    <div className="container" style={{maxWidth:'730px'}}>
      <SectionHeader
        heading="Request Offer"
        subheader="Get in touch for an offer or questions regarding the licensing model."
      />
      <div className="text-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-container">
              <ContactForm formspreeReceivingMail="info@smapiot.com" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
