import * as React from 'react';
import { Tag, SuggestionList } from '../../components';
import { SuggestionResults, useSuggestionList } from '../../hooks';

/**
 * TagInput props.
 */
export interface TagInputProps {
  /**
   * Handler to obtain the current value.
   * @param value The current tags.
   */
  onChange?(value: Array<string>): void;
  /**
   * Checks if the given tag can be added. By default, checks
   * against all other tags and only returns true if no other
   * tag matches the current one (case insensitve).
   * @param tag The tag proposed to be added.
   * @param value The other (currently added) tags.
   */
  onCheck?(tag: string, value: Array<string>): boolean;
  /**
   * Computes the display representation of the given value.
   * @param value The value to display.
   */
  onDisplay?(value: string): React.ReactNode;
  /**
   * Sets if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * The initial value for managed mode.
   */
  defaultValue?: Array<string>;
  /**
   * The current value for the controlled mode.
   */
  value?: Array<string>;
  /**
   * The maximum number of tags.
   */
  maxTags?: number;
  /**
   * The label of the input field, if any.
   */
  label?: React.ReactChild;
  /**
   * The error state to show, if any.
   */
  error?: boolean;
  /**
   * The onSuggest state to show, if any data was provided.
   */
  onSuggest?(input: string): SuggestionResults;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

function defaultDisplay(value: string) {
  return <>{value}</>;
}

function defaultCheck(tag: string, value: Array<string>) {
  const current = tag.toLowerCase();
  return !value.find((tag) => tag.toLowerCase() === current);
}

export const TagInput: React.FC<TagInputProps> = ({
  error,
  onChange,
  defaultValue,
  value = [],
  maxTags = Number.MAX_SAFE_INTEGER,
  label,
  disabled,
  onSuggest,
  onDisplay = defaultDisplay,
  onCheck = defaultCheck,
  children,
}) => {
  const [suffix, setSuffix] = React.useState('');
  const [tags, setTags] = React.useState(defaultValue);
  const [el, suggestionsInfos, setSuggestionsInfos, loadSuggestions] = useSuggestionList();
  const input = React.useRef(undefined);
  const managed = React.useMemo(() => defaultValue !== undefined, []);
  const chosenTags = managed ? tags : value;
  const noInput = disabled || chosenTags.length >= maxTags;

  const inputChanged = React.useCallback(() => {
    const search = input.current.textContent;

    if (typeof onSuggest === 'function') {
      const results = onSuggest?.(search);
      loadSuggestions(results);
    }
  }, [onSuggest]);

  const keyboardNavigation = (ev: React.KeyboardEvent) => {
    const { key } = ev;
    const useEntry = key === 'Enter' || key === ',' || key === ';' || key === 'Tab';
    const search = input.current.textContent;

    if (noInput) {
      if (key === 'Backspace') {
        removeTag(chosenTags.length - 1);
      }

      ev.preventDefault();
    } else {
      if (useEntry && suggestionsInfos.cursor !== -1) {
        const suggestion = suggestionsInfos.suggestions[suggestionsInfos.cursor];
        addTag(typeof suggestion === 'string' ? suggestion : suggestion.value);
        ev.preventDefault();
      } else if (key === 'ArrowDown' || key === 'ArrowUp') {
        const len = suggestionsInfos.suggestions.length;

        if (len > 0) {
          const delta = suggestionsInfos.isVisible ? (key === 'ArrowDown' ? +1 : -1) : 0;
          const cursor = (suggestionsInfos.cursor + delta + len) % len;
          setSuggestionsInfos((data) => ({ ...data, cursor, isVisible: true }));
        }

        ev.preventDefault();
      } else if (key === 'Escape') {
        setSuggestionsInfos((data) => ({ ...data, isVisible: false }));
        ev.preventDefault();
      } else if (useEntry && search && suggestionsInfos.cursor === -1) {
        addTag(search);
        ev.preventDefault();
      } else if (key === 'Backspace' && !search) {
        removeTag(chosenTags.length - 1);
        ev.preventDefault();
      }
    }
  };

  const updateSuffix = React.useCallback(() => {
    const currentTarget = input.current;
    const search = currentTarget.textContent;
    const invalid = !!search && currentTarget !== document.activeElement;
    const inFocus = currentTarget === document.activeElement;
    setSuffix(`${search || inFocus ? ' active' : ''}${error || invalid ? ' error' : ''}`);
  }, [error]);

  const changeTags = React.useCallback(
    (newTags: Array<string>) => {
      if (managed) {
        setTags(newTags);
      }

      input.current.textContent = '';
      setSuggestionsInfos((data) => ({ ...data, cursor: -1, isVisible: false }));
      updateSuffix();
      onChange?.(newTags);
    },
    [onChange],
  );

  const removeTag = React.useCallback(
    (i) => changeTags(chosenTags.filter((_, j) => i !== j)),
    [chosenTags, changeTags],
  );

  const addTag = React.useCallback(
    (value: string) => {
      const canAdd = typeof onCheck === 'function' ? onCheck(value, chosenTags) : undefined;

      if (canAdd ?? true) {
        changeTags([...chosenTags, value]);
      }
    },
    [chosenTags, changeTags],
  );

  const onInput = noInput ? undefined : inputChanged;
  const onFocus = noInput ? undefined : updateSuffix;

  return (
    <div className="form-group" ref={el}>
      <div className={`input-tag__container${suffix}`} {...{ disabled }}>
        <div className="input-tag">
          <ul className="input-tag__tags">
            {chosenTags.map((tag, i) => (
              <li key={tag}>
                <Tag label={onDisplay(tag)}>
                  <button type="button" onClick={() => removeTag(i)} disabled={disabled}>
                    +
                  </button>
                </Tag>
              </li>
            ))}
            <li className="input-tag__tags__input" style={{ visibility: noInput ? 'hidden' : 'visible' }}>
              <span
                ref={input}
                className="tag-label"
                contentEditable
                onInput={onInput}
                onChange={onInput}
                onKeyDown={keyboardNavigation}
                onFocus={onFocus}
                onBlur={onFocus}
              />
            </li>
          </ul>
        </div>
        {label && <label className={`text-floating${suffix}`}>{label}</label>}
      </div>
      {children}
      <SuggestionList
        onSelect={addTag}
        loading={suggestionsInfos.loading}
        visible={suggestionsInfos.isVisible}
        suggestions={suggestionsInfos.suggestions}
        cursor={suggestionsInfos.cursor}
      />
    </div>
  );
};
