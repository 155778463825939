import { ForwardedRef, useEffect, useRef } from 'react';

export function useForwardedRef<T>(forwardedRef: ForwardedRef<T>) {
  const targetRef = useRef<T>();

  useEffect(() => {
    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(targetRef.current);
      } else {
        forwardedRef.current = targetRef.current;
      }
    }
  });

  return targetRef;
}
