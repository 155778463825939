import { useEffect } from 'react';

export function useOutsideClick(container: React.MutableRefObject<HTMLElement>, handleClick: () => void) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && container.current && !container.current.contains(event.target)) {
        handleClick();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClick]);
}
