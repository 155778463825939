import * as React from 'react';
import { Checkbox } from '../Checkbox';
import { RadioButton } from '../RadioButton';

export interface ChoicesProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'value' | 'defaultValue' | 'onChange'> {
  /**
   * Sets the change handler to receive a new value.
   * @param value The updated choice state.
   */
  onChange?(value: Record<string, boolean>): void;
  /**
   * The initial value for managed mode.
   */
  defaultValue?: Record<string, boolean>;
  /**
   * The value object for the multiple choices.
   */
  value?: Record<string, boolean>;
  /**
   * The optional label of the multi-checkbox.
   */
  label?: React.ReactChild;
  /**
   * Indicates an error state of the multi-checkbox.
   */
  error?: boolean;
  /**
   * Indicates that a single exclusive choice should be
   * taken. Changes the inputs to radio buttons.
   */
  single?: boolean;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

/**
 * A multi-checkbox form element.
 */
export const Choices: React.FC<ChoicesProps> = ({
  onChange,
  error,
  label,
  value = {},
  defaultValue,
  children,
  single,
  ...props
}) => {
  const [choices, setChoices] = React.useState(defaultValue);
  const managed = React.useMemo(() => defaultValue !== undefined, []);
  const chosenChoices = managed ? choices : value;
  const Component = single ? RadioButton : Checkbox;

  return (
    <div className="form-group">
      <label className="text-floating">{label}</label>
      <div {...props} className="choices">
        {Object.keys(chosenChoices).map((s) => (
          <Component
            key={s}
            label={s}
            checked={chosenChoices[s]}
            onChange={(status) => {
              const choices = {
                ...chosenChoices,
              };

              if (single) {
                Object.keys(chosenChoices).forEach((choice) => (choices[choice] = false));
              }

              choices[s] = status;

              if (managed) {
                setChoices(choices);
              }

              onChange?.(choices);
            }}
          />
        ))}
      </div>
      {children}
    </div>
  );
};
