import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { BookSession } from './BookSession';
import { Contact } from './Contact';
import { Customers } from './Customers';
import { Footer } from './Footer';
import { Navigation, NavElement } from './Navigation';
import { Benefits } from './Benefits';
import { Intro } from './Intro';
import { PricingList } from './PricingList';
import { FeatureList } from './FeatureList';
import { TestimonialList } from './TestimonialList';

const navElements: Array<NavElement> = [
  {
    path: '#features',
    text: 'Features',
  },
  {
    path: '#pricing',
    text: 'Pricing',
  },
  {
    path: '#booksession',
    text: 'Book Session',
  },
  {
    path: '#contact',
    text: 'Contact',
  },
  {
    path: 'https://smapiot.com/about',
    text: 'About Us',
    target: '_blank',
  },
];

export const Layout: React.FC = () => (
  <>
    <BrowserRouter>
      <Navigation header="Piral Cloud" items={navElements} />
      <Intro />
      <Benefits />
      <FeatureList />
      <PricingList />
      <TestimonialList />
      <Customers />
      <BookSession />
      <Contact />
      <Footer
        aboutHeader="About Piral Cloud"
        aboutDescription="The Piral Cloud provides services for the dynamic composition of modular web applications and supports you in building state-of-the-art micro frontend solutions."
        contactMail="info@smapiot.com"
        gitHubLink="https://github.com/smapiot"
        twitterLink="https://twitter.com/smapiot"
        linkedInLink="https://de.linkedin.com/company/smapiot"
      />
    </BrowserRouter>
  </>
);
