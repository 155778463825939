import * as React from 'react';

export interface LinkCardProps {
  /**
   * The CSS clas for an icon such as fas fa-rocket.
   */
  icon: string;
  /**
   * The title of the card.
   */
  title: React.ReactChild;
  /**
   * The card's description.
   */
  description?: React.ReactChild;
  /**
   * The card's children.
   */
  children?: React.ReactNode;
}

export const LinkCard: React.FC<LinkCardProps> = ({ icon, title, description, children }) => (
  <div className="card link-card text-center">
    <i className={icon} />
    <h5>{title}</h5>
    <div>
      <small>{description}</small>
    </div>
    {children}
  </div>
);
