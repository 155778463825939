import * as React from 'react';
import { Button } from '../Button';

export interface PanelView {
  icon: React.ReactElement;
  toolTip?: string;
  Content: React.ComponentType;
}

export interface PanelProps {
  title: string;
  views: Array<PanelView>;
}

export const Panel: React.FC<PanelProps> = ({ title, views }) => {
  const [selectedView, setSelectedView] = React.useState(0);
  const handlers = React.useMemo(() => views.map((_, i) => () => setSelectedView(i)), [views.length]);
  const CurrentContent = views[selectedView].Content;
  return (
    <div className="panel">
      <div className="bar">
        <h3 className="panel-title">{title}</h3>
        <div>
          {views.map((view, index) => (
            <Button
              key={index}
              title={view.toolTip}
              onClick={handlers[index]}
              kind={index === selectedView ? 'primary' : 'secondary'}>
              {view.icon}
            </Button>
          ))}
        </div>
      </div>
      <CurrentContent />
    </div>
  );
};
