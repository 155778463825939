import * as React from 'react';

export interface Social {
  /**
   * The name of the social media network.
   */
  name: string;
  /**
   * The CSS class for an icon representing the network.
   */
  icon: string;
  /**
   * The full link against the profile.
   */
  link: string;
}

export interface MemberCardProps {
  /**
   * The URL to load the member's image from.
   */
  image: string;
  /**
   * The title of the member, usually the name.
   */
  title: React.ReactChild;
  /**
   * The member's role.
   */
  role: React.ReactChild;
  /**
   * The member's social media presences.
   */
  socials?: Array<Social>;
}

export const MemberCard: React.FC<MemberCardProps> = ({ image, title, role, socials = [] }) => (
  <div className="card member-card text-center">
    <div className="image">
      <img src={image} alt={typeof title === 'string' ? title : 'Member'} />
    </div>
    <h5>{title}</h5>
    <div className="role">
      <small>{role}</small>
    </div>
    <div className="social">
      {socials.map(({ name, icon, link }, i) => (
        <a key={`${name}-${i}`} title={name} href={link} target="_blank">
          <i className={icon} />
        </a>
      ))}
    </div>
  </div>
);
