import * as React from 'react';

export interface PricingCardProps {
  /**
   * The title of the card.
   */
  title: React.ReactChild;
  /**
   * The price to show on the card.
   */
  price: React.ReactChild;
  /**
   * The CSS class of an icon to show, e.g., fas fa-rocket.
   */
  icon: string;
  /**
   * Set this option to indicate that the pricing tier is not available right now.
   */
  unavailable?: boolean;
  /**
   * A list of options to show for this pricing tier.
   */
  options: Array<React.ReactChild>;
  /**
   * The card's children.
   */
  children?: React.ReactNode;
}

export const PricingCard: React.FC<PricingCardProps> = ({
  title,
  icon,
  price,
  unavailable,
  options,
  children,
}) => (
  <div className={`card pricing-card text-center ${unavailable ? 'not-available' : ''}`}>
    <div className="image">
      <i className={icon} />
    </div>
    <h5>{title}</h5>
    <div className="price">
      <sup>€</sup>
      <strong>{price}</strong>
      <sub>/ mo</sub>
    </div>
    <ul className="options">
      {options.map((option, i) => (
        <li key={i}>{option}</li>
      ))}
    </ul>
    {children}
  </div>
);
