import * as React from 'react';

export interface ImageTileProps {
  /**
   * The URL of the image to tile.
   */
  image: string;
  /**
   * The alternative text.
   */
  alt: string;
  /**
   * The size quantifier.
   */
  size: 'wide' | 'large' | 'small' | 'medium';
}

export const ImageTile: React.FC<ImageTileProps> = ({ image, alt, size }) => (
  <div className={`tile ${size}`}>
    <img src={image} alt={alt} />
  </div>
);
