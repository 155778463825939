import * as React from 'react';

export interface ProjectTechnology {
  /**
   * An ID, i.e., short name, of the technology.
   */
  id: string;
  /**
   * A shown name of the technology.
   */
  name: string;
  /**
   * An URL of the technology's logo.
   */
  image: string;
}

export interface ProjectPartner {
  /**
   * An URL of the partner's logo.
   */
  image: string;
  /**
   * The name of the partner.
   */
  name: string;
}

export interface ProjectCardProps {
  /**
   * The title of the card.
   */
  title: React.ReactChild;
  /**
   * The card's category.
   */
  category: React.ReactChild;
  /**
   * A description for the card.
   */
  description: React.ReactChild;
  /**
   * A set of technologies that has been used in this project.
   */
  technologies?: Array<ProjectTechnology>;
  /**
   * The client for the project.
   */
  partner?: ProjectPartner;
  /**
   * The card's children.
   */
  children?: React.ReactNode;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  category,
  description,
  partner,
  technologies = [],
  children,
}) => (
  <div className="card project-card">
    <div className="text-box text-card-content">
      <span className="headline">{category}</span>
      <h2 className="headline">{title}</h2>
      <p>{description}</p>
      <div className="tech-logos">
        {technologies.map(({ id, image, name }) => (
          <div key={id} className="tech-logo">
            <img src={image} alt={id} title={name} />
          </div>
        ))}
      </div>
      {children}
    </div>
    {partner && (
      <div className="partner-logo">
        <img src={partner.image} alt="Partner" title={partner.name} />
      </div>
    )}
  </div>
);
