import * as React from 'react';

export interface HashProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'className' | 'value' | 'defaultValue' | 'onChange'> {
  /**
   * The hash value to display.
   */
  children?: React.ReactNode;
}

export const Hash: React.FC<HashProps> = (props) => <span {...props} className="hash" />;
