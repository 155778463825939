import * as React from 'react';

export interface DropdownFieldProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  /**
   * Handler to obtain the current value.
   * @param value The current tags.
   */
  onChange?(value: string): void;
  /**
   * The label of the dropdown field, if any.
   */
  label?: React.ReactChild;
  /**
   * The content to display in the field.
   */
  children?: React.ReactNode;
}

export const DropdownField: React.FC<DropdownFieldProps> = ({
  label,
  value,
  onChange,
  children,
  ...props
}) => {
  const change = React.useCallback((e) => onChange?.(e.target.value), [onChange]);

  return (
    <div className="form-group">
      <div className={`form-control ${value ? 'active' : ''}`}>
        <select {...props} value={value} onChange={change}>
          {children}
        </select>
      </div>
      {label && <label className="text-floating">{label}</label>}
    </div>
  );
};
