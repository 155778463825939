import * as React from 'react';

export interface NotificationCardProps {
  /**
   * The title shown in the card.
   */
  title: React.ReactChild;
  /**
   * The card's actions.
   */
  actions: React.ReactChild;
  /**
   * The cards class name.
   */
  className?: string;
  /**
   * The body of the card.
   */
  children?: React.ReactNode;
}

export const NotificationCard = React.forwardRef<HTMLDivElement, NotificationCardProps>(
  ({ title, actions, children, className }, ref) => {
    return (
      <div className={`feed-card ${className || ''}`} ref={ref}>
        <div className="feed-card-header">
          <div className="feed-card-header-title">{title}</div>
          <div className="feed-card-header-actions">{actions}</div>
        </div>
        <div className="feed-card-body">{children}</div>
      </div>
    );
  },
);
