import * as React from 'react';
import { images } from '../../media';

export interface InvertedLogoProps {}

export const InvertedLogo: React.FC<InvertedLogoProps> = () => (
  <img src={images.smapiotWhite} alt="smapiot GmbH" />
);

export default InvertedLogo;
