import * as React from 'react';
import { Button } from '../../components/Button';

const defaultConsentKey = 'cookie-consent';
const defaultPrivacyPolicy = 'https://smapiot.com/legal/privacy';

export interface CookieConsentProps {
  /**
   * The key that is used for storing the consent information
   * in the local storage.
   */
  consentKey?: string;
  /**
   * The link to the (custom) privacy policy.
   */
  privacyPolicy?: string;
}

/**
 * A lightweight cookie consent popup.
 */
export const CookieConsent: React.FC<CookieConsentProps> = ({
  consentKey = defaultConsentKey,
  privacyPolicy = defaultPrivacyPolicy,
}) => {
  const [consent, setConsent] = React.useState(() => !!localStorage.getItem(consentKey));
  const understood = React.useCallback(() => {
    localStorage.setItem(consentKey, new Date().toJSON());
    setConsent(true);
  }, []);

  return (
    !consent && (
      <div className="cookie-consent">
        <div className="cookie-consent-container">
          <div>
            Our website utilizes cookies for basic non-personalized analytics and error tracking. By using our website,
            you agree to our{' '}
            <a href={privacyPolicy} target="_blank">
              Privacy Policy
            </a>{' '}
            and our cookies usage.
          </div>
          <Button onClick={understood}>Understood</Button>
        </div>
      </div>
    )
  );
};
