import * as React from 'react';
import { SectionHeader } from './SectionHeader';
import { Button } from '@smapiot/components';
import { getDocsLink, getPortalLink } from '../utils/linkUtils';

const features: Array<FeatureListItemProps> = [
  {
    header: 'Micro Frontend Discovery',
    description: 'Identify and fetch required Micro Frontends for a dynamic composition and smooth operation of your portal solution.',
    image: require('../images/feature_visual_01.svg'),
  },
  {
    header: 'Rule Management',
    description: 'Define and assign rules for delivering frontend modules to end users based on given conditions.',
    image: require('../images/feature_visual_02.svg'),
  },
  {
    header: 'Feed Management',
    description: 'Organize and configure the building blocks of your portal solution in containers called feeds.',
    image: require('../images/feature_visual_03.svg'),
  },
  {
    header: 'Config Management',
    description: 'Add a centrally managed configuration for the frontend modules and their components​.',
    image: require('../images/feature_visual_04.svg'),
  },
  {
    header: 'User Management',
    description: 'Invite colleagues to administrate the modules and settings for your dynamic web application.​',
    image: require('../images/feature_visual_05.svg'),
  },
  {
    header: 'Feature Flags',
    description: 'Control the appearance and functionality of your portal solution​ centrally in the backend.',
    image: require('../images/feature_visual_06.svg'),
  },
];

interface FeatureListItemProps {
  header: string;
  description: string;
  image: any;
}

const FeatureListItem: React.FC<FeatureListItemProps> = ({ header, description, image }) => (
  <div className="col-md-6">
    <div className="row">
      <div className="col-md-2 text-center p-3 ">
        <img width="60px" height="60px" src={image} alt="feed-service-feature-visualization" />
      </div>
      <div className="col-md-10 p-3 text-md-left text-center">
        <h3 className="text-nowrap">{header}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);
export const FeatureList: React.FC = () => (
  <div id="features">
    <div>
      <SectionHeader
        heading="Features"
        subheader="Booster your customer or service portal with the power of the Piral Cloud."
      />
      <div className="container">
        <div className="row">
          {features.map((f) => (
            <FeatureListItem key={f.header} image={f.image} header={f.header} description={f.description} />
          ))}
        </div>
      </div>
      <div className="container mb-5">
      <div className="row justify-content-center p-2">
        <div className="m-3">
          <a target="_blank" href={getPortalLink()}>
            <Button style={{ minWidth: '200px' }}>Get Started</Button>
          </a>
        </div>
        <div className="m-3">
          <a target="_blank" href={getDocsLink()}>
            <Button style={{ minWidth: '200px' }}>Browse Documentation</Button>
          </a>
        </div>
        </div>
      </div>
    </div>
  </div>
);
