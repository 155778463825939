import * as React from 'react';
import { SectionHeader } from './SectionHeader';
import { CarouselChild, CarouselContainer, QuoteCarouselItem } from '@smapiot/components';

export const TestimonialList: React.FC = () => (
  <div>
    <div className="container">
      <div className="row">
        <SectionHeader
          heading="Testimonials"
          subheader="Here's what some of our customers say about the Piral Cloud:"
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="quote-carousel">
            <CarouselContainer interval={20000}>
              <CarouselChild>
                <QuoteCarouselItem
                  title="Director Software Products - Windmöller & Hölscher KG"
                  name="Wolfgang Hoffmann"
                  image={require('../images/customer-logos/wuh-tm.png')}
                  quote="With the Piral Feed Service, we have the perfect tool at hand to centrally manage the provision of frontend modules for our administration portal. In addition to its valuable capabilities as a Micro Frontend discovery service, it also integrates nicely into our Kubernetes based cloud service platform."
                />
              </CarouselChild>
              <CarouselChild>
                <QuoteCarouselItem
                  title="CTO - CASABLANCA hotelsoftware GmbH"
                  name="Johannes Ehrhart"
                  image={require('../images/customer-logos/casablanca-tm.png')}
                  quote="We have been using Piral for one year and are more than happy with our decision. The Piral Feed Service makes it super easy to get started in the world of micro frontends. From the deployment to the functions that the feed service brings with it, everything is well thought out and with smapiot you gain a partner who can support you with expertise."
                />
              </CarouselChild>
              <CarouselChild>
                <QuoteCarouselItem
                  title="CTO - Consolinno Energy GmbH"
                  name="Jens Thirmeyer"
                  image={require('../images/customer-logos/consolinno-tm.png')}
                  quote="The Piral Feed Service was the missing piece for our Micro Frontend solution. It supports us perfectly in the development of functionalities in distributed feature teams and brings great flexibility for deploying our micro frontends seamlessly. Overall it represents a crucial building block for delivering a state-of-the-art frontend solution."
                />
              </CarouselChild>
              <CarouselChild>
                <QuoteCarouselItem
                  title="Software Architect - proWIN Winter GmbH"
                  name="Dennis Fischer"
                  image={require('../images/customer-logos/prowin-tm.png')}
                  quote="With its robust features and great flexibility, the Piral Feed Service has become a central part of our development workflow and has revolutionized the deployment and management of our micro frontends."
                />
              </CarouselChild>
            </CarouselContainer>
          </div>
        </div>
      </div>
    </div>
  </div>
);
