import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';

export interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * The (absolute or relative) URL to navigate to.
   */
  url: string;
  /**
   * The kind of navigation in case of a NavLink.
   */
  nav?: boolean | 'exact';
}

/**
 * Represents a generalized anchor element that resolves either to a plain <a> or
 * a <NavLink> or a <Link> depending if the given `url` is absolute, relative or
 * exact.
 */
export const Anchor: React.FC<AnchorProps> = ({ url, nav, ...props }) =>
  /^(https?|mailto|tel|fax):/.test(url) ? (
    <a href={url} {...props} />
  ) : nav ? (
    <NavLink to={url} exact={nav === 'exact'} {...props} />
  ) : (
    <Link to={url} {...props} />
  );
