import * as React from 'react';
import { useToggleIndices } from '../../hooks/useToggleIndices';
import { toggleAccordionEvent } from './constants';

export interface AccordionContainerProps extends Omit<React.HTMLAttributes<HTMLUListElement>, 'className'> {
  /**
   * Sets if multiple items can be open at the same time.
   */
  multiple?: boolean;
  /**
   * The content of the accordion.
   */
  children?: React.ReactNode;
}

/**
 * Represents an accordion.
 */
export const AccordionContainer: React.FC<AccordionContainerProps> = ({ children, multiple, ...props }) => {
  const container = React.useRef<HTMLUListElement>(null);
  const [openIndices, toggle] = useToggleIndices(multiple);

  React.useEffect(() => {
    const element = container.current;

    if (element) {
      const handler = (ev: CustomEvent) => {
        const { index } = ev.detail;
        toggle(index);
      };
      element.addEventListener(toggleAccordionEvent, handler);
      return () => element.removeEventListener(toggleAccordionEvent, handler);
    }
  }, [multiple]);

  return (
    <ul {...props} className="accordion" ref={container}>
      {React.Children.map(children, (child, i) => (
        <li key={i} className={openIndices.includes(i) ? 'accordion-open' : 'accordion-closed'}>
          {child}
        </li>
      ))}
    </ul>
  );
};
