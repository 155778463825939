import * as React from 'react';
import { useModal } from '../../hooks/useModal';

export interface ModalsContainerProps {
  /**
   * Indicates if the container is open.
   */
  open: boolean;
  /**
   * Callback to use when everything should be closed.
   */
  close(): void;
  /**
   * The content of the modal to show.
   */
  children?: React.ReactNode;
}

export const ModalsContainer: React.FC<ModalsContainerProps> = ({ open, close, children }) => {
  useModal(open);
  return (
    <div className="modal" onClick={close}>
      {children}
    </div>
  );
};
