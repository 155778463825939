import * as React from 'react';
import { getPureTime, oneDay, TimeFieldState, TimeFieldUpdate, toTimeString } from './internal/common';
import { PickerPopup } from './internal/PickerPopup';
import { useOutsideClick } from '../../hooks';

export interface TimeFieldProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'value' | 'defaultValue' | 'onChange'> {
  /**
   * Sets the change handler to receive a new value.
   * @param value The updated choice state.
   */
  onChange?(value: number): void;
  /**
   * The initial value for managed mode.
   */
  value?: string | number | Date;
  /**
   * The initial value for managed mode.
   */
  defaultValue?: string | number | Date;
  /**
   * The optional label of the time field.
   */
  label?: React.ReactChild;
  /**
   * Indicates an error state of the time field.
   */
  error?: boolean;
  /**
   * Indicates the minimum time range of the time field.
   */
  minTime?: string | number | Date;
  /**
   * Indicates the maximum time range of the time field.
   */
  maxTime?: string | number | Date;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
  /**
   * Indicates whether the field cannot be modified.
   */
  readOnly?: boolean;
}

export const TimeField: React.FC<TimeFieldProps> = ({
  onChange,
  error,
  label,
  value,
  minTime = 0,
  maxTime = oneDay,
  readOnly,
  defaultValue = minTime,
  children,
}) => {
  const el = React.useRef(undefined);
  const controlled = React.useMemo(() => value !== undefined, []);
  const initial = controlled ? value : defaultValue;

  const [state, dispatch] = React.useReducer(
    (state: TimeFieldState, values: TimeFieldUpdate) => {
      const { force, ...update } = values;
      const newState = {
        ...state,
        ...update,
      };

      if (!force && newState.time !== state.time) {
        const value = newState.time;
        setTimeout(() => onChange?.(value));
      }

      if (controlled && !force) {
        newState.time = state.time;
        newState.input = state.input;
      }

      return newState;
    },
    {
      valid: true,
      time: getPureTime(initial),
      input: toTimeString(initial),
      showPicker: false,
    },
  );
  const openPicker = React.useCallback(() => dispatch({ showPicker: true }), []);

  React.useEffect(() => {
    const input = value || defaultValue;
    const time = getPureTime(input);

    if (state.time !== time) {
      dispatch({
        valid: !!time,
        time,
        input: typeof input === 'string' ? input : toTimeString(input),
        force: true,
      });
    }
  }, [controlled ? value : undefined]);

  const handleClickOutsideCalendar = React.useCallback(() => {
    dispatch({ showPicker: false });
  }, []);

  useOutsideClick(el, handleClickOutsideCalendar);

  const change = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      const time = getPureTime(input);
      const invalid = time === 0;

      dispatch({
        valid: !invalid,
        time: time || minTime,
        input,
      });
    },
    [minTime, maxTime],
  );

  return (
    <div ref={el} className="form-group">
      <input
        className={`form-control${!error && state.valid ? '' : ' error'}`}
        type="text"
        readOnly={readOnly}
        placeholder="HH:mm:ss"
        value={state.input}
        onChange={change}
        onFocus={openPicker}
      />
      {label && <label className="text-floating">{label}</label>}
      <i className="fas fa-clock date-picker-icon" onClick={openPicker} />
      {children}
      {!readOnly && state.showPicker && (
        <PickerPopup
          value={state.time}
          min={getPureTime(minTime)}
          max={getPureTime(maxTime)}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};
