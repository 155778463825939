import * as React from 'react';

export interface TextBlockProps {
  /**
   * The URL of an image to display.
   */
  image: string;
  /**
   * The title of the block to display.
   */
  title: React.ReactChild;
  /**
   * Indicates if the order should be reversed.
   */
  reverse?: boolean;
  /**
   * The content of the text block.
   */
  children?: React.ReactNode;
}

export const TextBlock: React.FC<TextBlockProps> = ({ image, title, reverse, children }) => (
  <div className={`text-block ${reverse ? 'reverse' : 'normal'}`}>
    <div className="text-block-teaser">
      <img src={image} alt={typeof title === 'string' ? title : 'Teaser'} className="circle shadow" />
    </div>
    <div className="text-block-content">
      <h2>{title}</h2>
      {children}
    </div>
  </div>
);
