import * as React from 'react';

export interface RadioButtonProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  /**
   * Callback invoked when the value changes.
   * @param value The changed value.
   */
  onChange?(value: boolean): void;
  /**
   * The label of the input field, if any.
   */
  label?: React.ReactChild;
  /**
   * The error state to show, if any.
   */
  error?: boolean;
  /**
   * Additional content to display after the field.
   */
  children?: React.ReactNode;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ onChange, error, label, children, ...props }) => {
  const cls = `form-control${error ? ' error' : ''}`;
  const change = React.useCallback((e) => onChange?.(e.target.checked), [onChange]);

  return (
    <div className="form-group">
      <label className="checked-container">
        <input {...props} type="radio" className={cls} onChange={change} />
        <span className="checkmark round" />
        {label && <span className="label">{label}</span>}
      </label>
      {children}
    </div>
  );
};
