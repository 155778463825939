import * as React from 'react';
import { SectionHeader } from './SectionHeader';

const text_01 =
  'Using the Piral Cloud Services new features and improvements for your customers can be published faster' +
  ' and in higher frequency without impacting other areas of your portal solution.';
const text_02 =
  'Integrated with the Piral Cloud Services, your service portal will offer the specific features based on the end user`s role.' +
  ' With the personalized experience, your solution appears to be tailored to the needs of each individual user.';

const text_03 =
  'Due to the possibility to upgrade your customer portal incrementally by using the Piral Cloud Services, ' +
  ' it will be much easier for your customers to adapt to new features and functionalities.';

export const Benefits: React.FC = () => (
  <div className="bg-light py-4">
    <div className="text-container">
      <SectionHeader
      heading="Benefits"
      subheader="As a service portal provider, the Piral Cloud will enable you to offer an innovative and seamless customer experience."/>
    </div>
    <div className="container">
      <Benefit
        header="Reduced Time-To-Market"
        text={text_01}
        image={require('../images/benefits_speed.png')}
        alignImageLeft={true}
      />
      <Benefit
        header="Tailored for your Customers"
        text={text_02}
        image={require('../images/benefits_ux.png')}
        alignImageLeft={false}
      />
      <Benefit
        header="Easy to Learn and Use"
        text={text_03}
        image={require('../images/benefits_control.png')}
        alignImageLeft={true}
      />
    </div>
  </div>
);

interface BenefitProps {
  header: string;
  text: string;
  image: any;
  alignImageLeft: boolean;
}

const Benefit: React.FC<BenefitProps> = ({ header, text, image, alignImageLeft }) => {
  const [matches, setMatches] = React.useState(window.matchMedia('(min-width: 768px)').matches);

  React.useEffect(() => {
    const handler = (e: { matches: boolean | ((prevState: boolean) => boolean) }) => setMatches(e.matches);
    window.matchMedia('(min-width: 768px)').addListener(handler);
  }, []);

  return (
    <div className={matches ? 'd-flex align-items-center py-0' : 'd-flex flex-column align-items-center py-0'}>
      {(alignImageLeft || !matches) && <img src={image} style={{ width: '200px' }} />}
      <div className="m-4">
        <h3>{header}</h3>
        <p className={'p-large'}>{text}</p>
      </div>
      {!alignImageLeft && matches && <img src={image} style={{ width: '200px' }} />}
    </div>
  );
};
