import * as React from 'react';

export interface TabItemProps {
  /**
   * The title of the tab.
   */
  title: string;
  /**
   * The content of the tab.
   */
  children?: React.ReactNode;
}

export const TabItem: React.FC<TabItemProps> = ({ children }) => <div>{children}</div>;
