import * as React from 'react';
import { CarouselChild } from './CarouselChild';
import { useCarousel } from '../../hooks/useCarousel';

export interface CarouselContainerProps {
  /**
   * The interval, i.e., time between slides in milliseconds.
   */
  interval?: number;
  /**
   * The content of the carousel, i.e., the slides.
   */
  children?: React.ReactNode;
}

export const CarouselContainer: React.FC<CarouselContainerProps> = ({ children, interval = 5000 }) => {
  const slides = React.Children.toArray(children);
  const length = slides.length;
  const [active, setActive, handlers, style] = useCarousel(length, interval);

  return (
    length > 0 && (
      <div className="carousel">
        <ol className="carousel-indicators">
          {slides.map((_, index) => (
            <li onClick={() => setActive(index)} key={index} className={`${active === index ? 'active' : ''}`} />
          ))}
        </ol>
        <div className="carousel-content" {...handlers} style={style}>
          <CarouselChild>{slides[slides.length - 1]}</CarouselChild>
          {slides.map((slide, index) => (
            <CarouselChild key={index}>{slide}</CarouselChild>
          ))}
          <CarouselChild>{slides[0]}</CarouselChild>
        </div>
      </div>
    )
  );
};
