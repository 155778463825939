import * as React from 'react';

export interface IconCardProps {
  /**
   * The icon as a CSS class, such as fas fa-rocket.
   */
  icon: string;
  /**
   * The title shown in the card.
   */
  title: React.ReactChild;
  /**
   * The card's description.
   */
  description: React.ReactChild;
}

export const IconCard: React.FC<IconCardProps> = ({ icon, title, description }) => (
  <div className="card icon-card text-center">
    <i className={icon} />
    <h5>{title}</h5>
    <p>{description}</p>
  </div>
);
