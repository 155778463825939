import * as React from 'react';
import { Button } from '../Button';

export interface TabTitleProps {
  /**
   * Set to true when the tab is selected
   */
  active: boolean;
  /**
   * Title label to be displayed
   */
  title: string;
  /**
   * Will be called when the tab is selected
   */
  setActive(): void;
}

export const TabTitle: React.FC<TabTitleProps> = ({ title, setActive, active }) => {
  return (
    <li>
      <Button type="button" onClick={setActive} kind={active ? 'primary' : 'secondary'}>
        {title}
      </Button>
    </li>
  );
};
