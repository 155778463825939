import * as React from 'react';

export interface InfoProps extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The info text to display.
   */
  children?: React.ReactNode;
}

/**
 * An Info component displaying an info symbol with some text.
 */
export const Info: React.FC<InfoProps> = ({ children, ...props }) => (
  <p {...props}>
    <small>
      <i className="fas fa-info-circle" /> {children}
    </small>
  </p>
);
