import * as React from 'react';

export interface FormProps extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  /**
   * Handler that is called when the form is submitted.
   */
  onSubmit?(): void;
  /**
   * The content of the form.
   */
  children?: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({ onSubmit, ...props }) => {
  const submit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      onSubmit?.();
    },
    [onSubmit],
  );
  return <form {...props} onSubmit={submit} />;
};
