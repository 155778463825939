import * as React from 'react';

export interface QuoteCarouselItemProps {
  /**
   * The role of the person making the quote.
   */
  title: React.ReactChild;
  /**
   * The name of the person being quoted.
   */
  name: React.ReactChild;
  /**
   * The URL of the persons's profile image.
   */
  image: string;
  /**
   * The actual quote to display.
   */
  quote: React.ReactChild;
}

export const QuoteCarouselItem: React.FC<QuoteCarouselItemProps> = ({ title, name, image, quote }) => (
  <div className="card member-card text-center no-select">
    <div className="image">
      <img src={image} className="client-image" alt={typeof name === 'string' ? name : 'Client'} />
    </div>
    <h5>{name}</h5>
    <div className="role">
      <small>{title}</small>
    </div>
    <blockquote>{quote}</blockquote>
  </div>
);
